@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    @apply font-sans leading-default;
    overflow-x: hidden;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

@layer components {
    .custom-xs {
        @apply text-xs font-normal;
    }

    .custom-xs-med {
        @apply text-xs font-medium;
    }

    .custom-functional {
        @apply desktop:text-s tablet:text-s mobile:text-xs font-normal;
    }

    .custom-functional-med {
        @apply desktop:text-s tablet:text-s mobile:text-xs font-medium;
    }

    .custom-body {
        @apply desktop:text-lg tablet:text-lg mobile:text-base font-normal font-helvetica;
    }

    .custom-body-med {
        @apply desktop:text-lg tablet:text-lg mobile:text-base font-medium;
    }

    .custom-16 {
        @apply text-base font-normal;
    }

    .custom-16-med {
        @apply text-base font-medium;
    }

    .custom-16-semi {
        @apply text-base font-semibold;
    }

    .custom-title-sm {
        @apply text-xl font-semibold;
    }

    .custom-title {
        @apply text-2xl font-semibold;
    }

    .custom-title-lg {
        @apply text-3xl font-semibold;
    }

    .custom-display {
        @apply text-display font-semibold;
    }

    .show-mobile {
        @apply desktop-lg:hidden desktop:hidden tablet:hidden mobile:block;
    }

    .show-desktop {
        @apply desktop:block tablet:hidden mobile:hidden;
    }

    .show-tablet {
        @apply desktop:hidden tablet:block mobile:hidden;
    }

    .show-desktop-lg {
        @apply desktop-lg:block mobile:hidden;
    }

    .show-desktop-lg-desktop {
        @apply desktop-lg:block desktop:block mobile:hidden;
    }

    .show-mobile-tablet {
        @apply desktop:hidden tablet:block mobile:block;
    }

    .show-desktop-tablet {
        @apply desktop:block tablet:block mobile:hidden;
    }

    .show-desktop-mobile {
        @apply desktop:block tablet:hidden mobile:block;
    }

    .show-all {
        @apply desktop:block tablet:block mobile:block;
    }

    .margin-adaptive {
        @apply max-w-[1100px] desktop:px-4 tablet:px-4 mobile:px-4;
    }

    .classes-column-header {
        @apply py-4 text-sm text-gray-700 font-normal;
    }

    .classes-table-content {
        @apply py-4 text-sm text-black font-normal;
    }

    .sexy-border {
        @apply rounded-3xl shadow-sm border border-gray-200;
    }

    .input-entry-box {
        @apply sexy-border rounded-lg focus-within:border-black font-semibold text-gray-500 focus-within:text-black h-9 text-smallish;
    }

    .actual-input {
        @apply outline-none border-none placeholder:font-normal placeholder-gray-400 h-full focus:border-none focus:ring-0 text-smallish bg-transparent p-0 flex-grow;
    }

    .sexy-button {
        @apply bg-black text-white text-sm font-medium py-2 px-4 rounded-full;
    }

    .less-sexy-button {
        @apply px-4 py-2 rounded-3xl border-[1px] border-black hover:bg-gray-200 text-smallish font-medium;
    }

    .you-dirty-dog {
        @apply text-red-500 text-xsmallish italic ml-2.5 mt-1;
    }

    .radio-style {
        @apply text-black focus:ring-transparent border-gray-300;
    }

    .constructor-vertical-margin {
        @apply h-[50px];
    }

    .constructor-steps-width {
        @apply w-[54px];
    }

    .margin-below-topbar {
        @apply mt-24;
    }

    .topbar-padding {
        @apply pb-2 pt-8;
    }

    .topbar-leftmost-icon-overaching-size {
        @apply size-12;
    }

    .topbar-text-size {
        @apply text-xl;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .hide-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .hide-scrollbar {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }

    .essay-rubric-input-box {
        @apply sexy-border rounded-lg mb-4 focus:ring-0 focus:sexy-border focus:rounded-lg w-full min-h-20 hide-scrollbar;
    }

    .feedback-container {
        height: auto;
        /* Allow the container to expand based on content */
        overflow: visible;
        /* Prevent the scrollbar from appearing */
    }

    .pdf-viewer {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .pdf-container {
        display: flex;
        justify-content: center;
        background-color: #525659;
        padding: 1rem;
        overflow: hidden;
    }

    .pdf-container canvas {
        height: auto !important;
        overflow: hidden;
    }

    .hide-scrollbar {
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE and Edge */
    }

    .hide-scrollbar::-webkit-scrollbar {
        display: none; /* Chrome, Safari and Opera */
    }

}