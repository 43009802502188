:root {
  --background-colors--primary: var(--swatch--white);
  --fonts-family--paragraph-font: Lato, sans-serif;
  --font-colors--paragraph: var(--swatch--slate-blue);
  --font-colors--heading-gradient-1: var(--swatch--dark-midnight-blue);
  --font-colors--heading-gradient-2: var(--swatch--midnight-blue);
  --fonts-family--heading-font: Lato, sans-serif;
  --font-colors--heading-primary: var(--swatch--dark-midnight-blue);
  --swatch--black: black;
  --font-colors--link: var(--swatch--dark-midnight-blue);
  --font-colors--link-hover: var(--swatch--midnight-blue);
  --swatch--white: white;
  --swatch--grey\<deleted\|variable-94a2a119-f0c5-c24a-a78a-21e8abcbac88\>: #242424;
  --swatch--periwinkle-blue\<deleted\|variable-e2b23002-dc64-f91c-e772-558067eb89c2\>: #5153ce;
  --button-primary--border: var(--swatch--dark-midnight-blue);
  --button-primary--border-radius: 20rem;
  --button-primary--background: var(--swatch--dark-midnight-blue);
  --button-primary--text: var(--swatch--white);
  --button-primary--border-hover: var(--swatch--midnight-blue);
  --button-primary--background-hover: var(--swatch--midnight-blue);
  --button-primary--text-hover: var(--swatch--blue-gray);
  --button-secondary--border: var(--swatch--dark-midnight-blue);
  --button-secondary--background: var(--swatch--transparent);
  --button-secondary--text: var(--swatch--dark-midnight-blue);
  --button-secondary--border-hover: var(--swatch--midnight-blue);
  --button-secondary--background-hover: var(--swatch--transparent);
  --button-secondary--text-hover: var(--swatch--midnight-blue);
  --swatch--transparent: rgba(255, 255, 255, 0);
  --border--strokes-2: var(--swatch--blue-gray);
  --form--border: var(--border--strokes-2);
  --border--radius-small: .5rem;
  --form--background: var(--background-colors--secondary);
  --form--place-holder-text: var(--font-colors--paragraph);
  --form--focused-border: var(--font-colors--paragraph);
  --form--focused-text: var(--font-colors--heading-primary);
  --icon--icon: var(--swatch--midnight-blue);
  --border--strokes: var(--swatch--light-steel-blue);
  --background-colors--secondary: var(--swatch--light-blue-gray);
  --background-colors--gradient-2: var(--swatch--teal-blue);
  --background-colors--gradient-1: var(--swatch--deep-purple);
  --border--radius-large: 1rem;
  --background-colors--tertiary: var(--swatch--white);
  --swatch--charcoal\<deleted\|variable-7ce553ac-38bb-d015-a0dc-c7f61c56011f\>: #131121;
  --swatch--silver-steel\<deleted\|variable-a214e77f-c16f-d580-fd2e-9b27c061e17d\>: #91969a;
  --swatch--deep-purple: #5c258d;
  --swatch--teal-blue: #4389a2;
  --swatch--dark-midnight-blue: #082130;
  --swatch--light-blue-gray: #f8f9fd;
  --swatch--light-steel-blue: #ebecf0;
  --swatch--blue-gray: #cfd5e5;
  --swatch--midnight-blue: #0d4262;
  --swatch--slate-blue: #50636c;
}

.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.w-layout-vflex {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.w-form-formradioinput--inputType-custom {
  width: 12px;
  height: 12px;
  border: 1px solid #ccc;
  border-radius: 50%;
}

.w-form-formradioinput--inputType-custom.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom.w--redirected-checked {
  border-width: 4px;
  border-color: #3898ec;
}

.w-checkbox {
  margin-bottom: 5px;
  padding-left: 20px;
  display: block;
}

.w-checkbox:before {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox:after {
  content: " ";
  clear: both;
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox-input {
  float: left;
  margin: 4px 0 0 -20px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  width: 12px;
  height: 12px;
  border: 1px solid #ccc;
  border-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-color: #3898ec;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3898ec;
}

.page-wrapper body {
  background-color: var(--background-colors--primary);
  font-family: var(--fonts-family--paragraph-font);
  color: var(--font-colors--paragraph);
  font-size: 1rem;
  line-height: 1.5;
}

.page-wrapper h1 {
  background-image: linear-gradient(to bottom, var(--font-colors--heading-gradient-1), var(--font-colors--heading-gradient-2));
  font-family: var(--fonts-family--heading-font);
  color: var(--font-colors--heading-primary);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 1.2;
}

.page-wrapper h2 {
  background-image: linear-gradient(to bottom, var(--font-colors--heading-gradient-1), var(--font-colors--heading-gradient-2));
  font-family: var(--fonts-family--heading-font);
  color: var(--font-colors--heading-primary);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2;
}

.page-wrapper h3 {
  background-image: linear-gradient(to bottom, var(--font-colors--heading-gradient-1), var(--font-colors--heading-gradient-2));
  font-family: var(--fonts-family--heading-font);
  color: var(--font-colors--heading-primary);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.2;
}

.page-wrapper h4 {
  background-image: linear-gradient(to bottom, var(--font-colors--heading-gradient-1), var(--font-colors--heading-gradient-2));
  font-family: var(--fonts-family--heading-font);
  color: var(--font-colors--heading-primary);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.3;
}

.page-wrapper h5 {
  background-image: linear-gradient(to bottom, var(--font-colors--heading-gradient-1), var(--font-colors--heading-gradient-2));
  font-family: var(--fonts-family--heading-font);
  color: var(--font-colors--heading-primary);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.4;
}

.page-wrapper h6 {
  background-image: linear-gradient(to bottom, var(--font-colors--heading-gradient-1), var(--font-colors--heading-gradient-2));
  font-family: var(--fonts-family--heading-font);
  color: var(--font-colors--heading-primary);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.4;
}

.page-wrapper p {
  margin-bottom: 0;
}

.page-wrapper a {
  border-color: var(--swatch--black);
  /* color: var(--font-colors--link); */
  /* text-decoration: underline; */
}

.page-wrapper a:hover {
  color: var(--font-colors--link-hover);
}

.page-wrapper ul {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-left: 1.25rem;
}

.page-wrapper ol {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-left: 1.5rem;
}

.page-wrapper li {
  margin-top: .25rem;
  margin-bottom: .25rem;
  padding-left: .5rem;
}

.page-wrapper img {
  max-width: 100%;
  display: inline-block;
}

.page-wrapper label {
  margin-bottom: .25rem;
  font-weight: 500;
}

.page-wrapper blockquote {
  border-left: .1875rem solid var(--swatch--black);
  margin-bottom: 0;
  padding: .75rem 1.25rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.page-wrapper figure {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.page-wrapper figcaption {
  text-align: center;
  margin-top: .25rem;
}

.max-width-full {
  width: 100%;
  max-width: none;
}

.text-style-link {
  text-decoration: underline;
}

.max-width-xlarge {
  width: 100%;
  max-width: 64rem;
}

.max-width-medium {
  width: 100%;
  max-width: 35rem;
}

.container-large {
  width: 100%;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
}

.align-center {
  margin-left: auto;
  margin-right: auto;
}

.max-width-large {
  width: 100%;
  max-width: 48rem;
}

.max-width-small {
  width: 100%;
  max-width: 30rem;
}

.form-message-error {
  margin-top: .75rem;
  padding: .75rem;
}

.utility-component {
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  display: flex;
}

.utility-form-block {
  max-width: 20rem;
  text-align: center;
  flex-direction: column;
  display: flex;
}

.utility-form {
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.container-medium {
  width: 100%;
  max-width: 64rem;
  margin-left: auto;
  margin-right: auto;
}

.container-small {
  width: 100%;
  max-width: 48rem;
  margin-left: auto;
  margin-right: auto;
}

.margin-tiny {
  margin: .25rem;
}

.margin-xxsmall {
  margin: .5rem;
}

.margin-xsmall {
  margin: 1rem;
}

.margin-small {
  margin: 1.5rem;
}

.margin-medium {
  margin: 2rem;
}

.margin-large {
  margin: 3rem;
}

.margin-xlarge {
  margin: 4rem;
}

.margin-xxlarge {
  margin: 5rem;
}

.margin-huge {
  margin: 6rem;
}

.padding-xxsmall {
  padding: .5rem;
}

.padding-xsmall {
  padding: 1rem;
}

.padding-medium {
  padding: 2rem;
}

.padding-xlarge {
  padding: 4rem;
}

.padding-xxlarge {
  padding: 5rem;
}

.margin-top,
.margin-top.margin-huge,
.margin-top.margin-small,
.margin-top.margin-medium,
.margin-top.margin-xxlarge,
.margin-top.margin-xlarge {
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
}

.margin-bottom {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

.margin-bottom.margin-xxlarge {
  margin: 0 0 1.7rem;
}

.margin-bottom.margin-medium,
.margin-bottom.margin-tiny,
.margin-bottom.margin-small,
.margin-bottom.margin-large,
.margin-bottom.margin-xlarge,
.margin-bottom.margin-huge,
.margin-bottom.margin-xxsmall,
.margin-bottom.margin-xsmall {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

.margin-vertical,
.margin-vertical.margin-medium,
.margin-vertical.margin-xxsmall,
.margin-vertical.margin-xsmall {
  margin-left: 0;
  margin-right: 0;
}

.padding-top,
.padding-top.padding-medium {
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

.padding-bottom,
.padding-bottom.padding-xxlarge {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}

.padding-vertical,
.padding-vertical.padding-xxlarge,
.padding-vertical.padding-xsmall,
.padding-vertical.padding-xlarge {
  padding-left: 0;
  padding-right: 0;
}

.text-weight-medium {
  font-weight: 500;
}

.text-size-medium {
  color: var(--font-colors--paragraph);
  font-size: 1.125rem;
}

.heading-style-h2 {
  background-image: linear-gradient(to bottom, var(--font-colors--heading-gradient-1), var(--font-colors--heading-gradient-2));
  color: var(--swatch--white);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2;
}

.heading-style-h6 {
  background-image: linear-gradient(to bottom, var(--font-colors--heading-gradient-1), var(--font-colors--heading-gradient-2));
  color: var(--swatch--white);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.4;
}

.form-radio {
  align-items: center;
  margin-bottom: 0;
  padding-left: 1.125rem;
  display: flex;
}

.heading-style-h5 {
  background-image: linear-gradient(to bottom, var(--font-colors--heading-gradient-1), var(--font-colors--heading-gradient-2));
  color: var(--swatch--white);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.4;
}

.heading-style-h1 {
  background-image: linear-gradient(to bottom, var(--font-colors--heading-gradient-1), var(--font-colors--heading-gradient-2));
  color: var(--swatch--white);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 1.2;
}

.form-checkbox-icon {
  width: 1.125rem;
  height: 1.125rem;
  min-height: 1.125rem;
  min-width: 1.125rem;
  border: 1px solid var(--swatch--grey\<deleted\|variable-94a2a119-f0c5-c24a-a78a-21e8abcbac88\>);
  cursor: pointer;
  border-radius: 0;
  margin-top: 0;
  margin-left: -1.25rem;
  margin-right: .5rem;
  transition: all .2s;
}

.form-checkbox-icon.w--redirected-checked {
  border-width: 1px;
  border-color: var(--swatch--periwinkle-blue\<deleted\|variable-e2b23002-dc64-f91c-e772-558067eb89c2\>);
  box-shadow: none;
  background-color: #000;
  background-size: 16px 16px;
}

.form-checkbox-icon.w--redirected-focus {
  box-shadow: none;
  border-color: #000;
}

.form-checkbox-label {
  margin-bottom: 0;
}

.text-size-tiny {
  font-size: .75rem;
}

.field-label {
  margin-bottom: .5rem;
  font-weight: 400;
}

.button {
  border: 1.2px solid var(--button-primary--border);
  border-radius: var(--button-primary--border-radius);
  background-color: var(--button-primary--background);
  color: var(--button-primary--text);
  text-align: center;
  margin-top: 25px;
  padding: .75rem 1.5rem;
  transition: color .2s, border-color .2s, background-color .2s;
}

.button:hover {
  border-color: var(--button-primary--border-hover);
  background-color: var(--button-primary--background-hover);
  color: var(--button-primary--text-hover);
}

.button.is-link {
  color: var(--font-colors--link);
  background-color: rgba(0, 0, 0, 0);
  border-style: none;
  padding: .25rem 0;
  line-height: 1;
  text-decoration: none;
}

.button.is-link:hover {
  color: var(--font-colors--link-hover);
}

.button.is-link.is-icon {
  grid-column-gap: .5rem;
  grid-row-gap: .5rem;
}

.button.is-link.is-icon:hover {
  color: var(--font-colors--link-hover);
}

.button.is-icon {
  grid-column-gap: .75rem;
  grid-row-gap: .75rem;
  justify-content: center;
  align-items: center;
  display: flex;
}

.button.is-secondary {
  border-color: var(--button-secondary--border);
  background-color: var(--button-secondary--background);
  color: var(--button-secondary--text);
}

.button.is-secondary:hover {
  border-color: var(--button-secondary--border-hover);
  background-color: var(--button-secondary--background-hover);
  color: var(--button-secondary--text-hover);
}

.button.is-secondary.is-icon {
  text-decoration: none;
}

.button.is-secondary.is-alternate {
  background-color: var(--swatch--transparent);
  color: var(--background-colors--primary);
}

.button.is-secondary.is-alternate:hover {
  border-color: var(--border--strokes-2);
  background-color: var(--swatch--transparent);
  color: var(--border--strokes-2);
}

.button.is-small {
  margin-top: 0;
  padding: .5rem 1.25rem;
}

.button.is-alternate {
  border-color: var(--background-colors--primary);
  background-color: var(--background-colors--primary);
  color: var(--font-colors--heading-primary);
}

.button.is-alternate:hover {
  border-color: var(--border--strokes-2);
  background-color: var(--border--strokes-2);
}

.heading-style-h4 {
  background-image: linear-gradient(to bottom, var(--font-colors--heading-gradient-1), var(--font-colors--heading-gradient-2));
  color: var(--swatch--white);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.3;
}

.form-radio-label {
  margin-bottom: 0;
}

.text-size-regular {
  font-size: 1rem;
}

.heading-style-h3 {
  background-image: linear-gradient(to bottom, var(--font-colors--heading-gradient-1), var(--font-colors--heading-gradient-2));
  color: var(--swatch--white);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.2;
}

.form-input {
  height: auto;
  min-height: 2.75rem;
  border: 1px solid var(--form--border);
  border-radius: var(--border--radius-small);
  background-color: var(--form--background);
  color: var(--form--place-holder-text);
  margin-bottom: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.6;
}

.form-input:focus {
  border-color: var(--form--focused-border);
  color: var(--form--focused-text);
}

.form-input::-ms-input-placeholder {
  color: var(--form--place-holder-text);
}

.form-input::placeholder {
  color: var(--form--place-holder-text);
}

.form-input.is-text-area {
  height: auto;
  min-height: 11.25rem;
  padding-top: .75rem;
  padding-bottom: .75rem;
  overflow: auto;
}

.form-input.is-select-input {
  background-image: none;
}

.button-group {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.button-group.is-center {
  justify-content: center;
}

.text-color-white {
  color: var(--swatch--white);
  -webkit-text-fill-color: inherit;
  background-image: none;
  background-clip: border-box;
}

.text-weight-semibold {
  font-weight: 600;
}

.icon-embed-xxsmall {
  width: 1rem;
  height: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.icon-embed-xsmall {
  width: 1.5rem;
  height: 1.5rem;
  color: var(--icon--icon);
  background-color: #f7f6f6;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-position: 0 0;
  background-size: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.form-radio-icon {
  width: 1.125rem;
  height: 1.125rem;
  min-height: 1.125rem;
  min-width: 1.125rem;
  border: 1px solid var(--swatch--grey\<deleted\|variable-94a2a119-f0c5-c24a-a78a-21e8abcbac88\>);
  cursor: pointer;
  border-radius: 100px;
  margin-top: 0;
  margin-left: -1.125rem;
  margin-right: .5rem;
}

.form-radio-icon.w--redirected-checked {
  border-width: 6px;
  border-color: var(--border--strokes-2);
  background-color: var(--background-colors--primary);
  background-image: none;
}

.form-radio-icon.w--redirected-focus {
  box-shadow: none;
  margin-top: 0;
}

.text-align-center {
  text-align: center;
}

.text-size-small {
  font-size: .875rem;
}

.form-field-wrapper {
  position: relative;
}

.icon-1x1-small {
  width: 3rem;
  height: 3rem;
  max-height: 100%;
  aspect-ratio: auto;
}

.text-rich-text h1 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.text-rich-text blockquote {
  border-left-color: var(--border--strokes-2);
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.text-rich-text h2,
.text-rich-text h3,
.text-rich-text h4 {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.text-rich-text h5,
.text-rich-text h6 {
  margin-top: 1.25rem;
  margin-bottom: 1rem;
}

.text-rich-text p {
  margin-bottom: 1rem;
}

.text-rich-text a {
  text-decoration: underline;
}

.text-rich-text figcaption {
  border-left: 2px solid var(--swatch--black);
  text-align: left;
  margin-top: .5rem;
  padding-left: .5rem;
  font-size: .875rem;
}

.form-checkbox {
  align-items: center;
  margin-bottom: 0;
  padding-left: 1.25rem;
  display: flex;
}

.icon-1x1-medium {
  width: 3rem;
  height: 3rem;
}

.text-size-large {
  font-size: 1.25rem;
}

.padding-global {
  padding-left: 5%;
  padding-right: 5%;
}

.padding-section-medium {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.padding-section-large {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.navbar-component {
  width: 100%;
  min-height: 4.5rem;
  border-bottom: 1px solid var(--border--strokes);
  background-color: var(--background-colors--secondary);
  align-items: center;
  margin-top: 0;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.navbar-container {
  width: 100%;
  height: 100%;
  max-width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.navbar-logo-link {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.navbar-menu {
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-left: 1.5rem;
  display: flex;
  position: static;
}

.navbar-link {
  color: var(--font-colors--link);
  padding: 1.5rem 1rem;
  transition: color .2s;
}

.navbar-link:hover {
  color: var(--font-colors--link-hover);
}

.navbar-link.w--current {
  color: var(--font-colors--link);
}

.navbar-menu-right {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: none;
}

.navbar-menu-desktop {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  flex-direction: row;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-self: center;
  align-items: center;
  display: flex;
}

.section-hero-header {
  position: relative;
  overflow: hidden;
}

.header-component {
  perspective: 1300px;
  mix-blend-mode: normal;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.header-blur {
  width: 60%;
  height: 10%;
  background-color: var(--background-colors--gradient-2);
  filter: blur(80px);
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: auto;
  bottom: 5%;
  left: 0%;
  right: 0%;
}

.text-color-gradient {
  background-image: linear-gradient(to right, var(--background-colors--gradient-1), var(--background-colors--gradient-2));
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  position: static;
}

.header-image {
  border: 1px solid var(--border--strokes-2);
  border-radius: var(--border--radius-large);
  overflow: hidden;
}

.hero-header-image-wrapper {
  z-index: 1;
  border-radius: var(--border--radius-large);
  background-color: var(--background-colors--primary);
  padding: 0;
  position: relative;
  overflow: hidden;
}

.ellipse {
  width: 10rem;
  height: 10rem;
  background-color: var(--background-colors--gradient-1);
  filter: blur(40px);
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: auto;
}

.header-image-block {
  border-radius: var(--border--radius-large);
  position: relative;
  overflow: hidden;
}

.hero-header-image {
  width: 100%;
  height: 100%;
  border-radius: var(--border--radius-small);
  overflow: hidden;
}

.section-logo {
  overflow: hidden;
}

.logo-component {
  display: flex;
}

.logo-list {
  grid-column-gap: 1.5rem;
  grid-row-gap: 1rem;
  white-space: nowrap;
  flex-wrap: wrap;
  grid-template-rows: auto;
  grid-template-columns: 12.5rem 12.5rem 12.5rem 12.5rem 12.5rem 12.5rem 12.5rem 12.5rem;
  grid-auto-columns: 1fr;
  justify-content: flex-end;
  align-items: center;
  justify-items: start;
  margin-right: 2rem;
  display: grid;
}

.logo-wrapper {
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.logo {
  width: 9rem;
}

.service-component {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.service-list {
  grid-column-gap: 3rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  justify-items: center;
  display: grid;
}

.service-item {
  width: 100%;
  border: 1px solid var(--border--strokes);
  border-radius: var(--border--radius-small);
  background-color: var(--background-colors--secondary);
  background-image: linear-gradient(to bottom, var(--background-colors--secondary), var(--background-colors--tertiary));
  text-align: center;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  display: flex;
}

.service-image-wrapper {
  width: 100%;
  border-radius: var(--border--radius-small);
  position: relative;
  overflow: hidden;
}

.feature-component {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.collection-list-wrapper {
  align-self: stretch;
}

.feature-list {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.collection-item {
  display: flex;
}

.layout-component {
  grid-column-gap: 5rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.layout-image-wrapper {
  border: 1px solid var(--border--strokes);
  border-radius: var(--border--radius-small);
  perspective: 1000px;
  padding: .25rem;
  overflow: hidden;
}

.feature-circle {
  width: 10rem;
  height: 10rem;
  background-color: var(--background-colors--gradient-1);
  filter: blur(20px);
  border-radius: 50px;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: auto;
}

.layout-image-block {
  border-radius: var(--border--radius-small);
  perspective: 1300px;
  position: relative;
  overflow: hidden;
}

.layout-image {
  width: 100%;
  height: 100%;
}

.section-video-header {
  position: relative;
  overflow: hidden;
}

.video-header-component {
  perspective: 1300px;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.video-header-lightbox {
  width: 100%;
  border: 1px solid var(--border--strokes);
  border-radius: var(--border--radius-large);
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.video-header-lightbox-image {
  object-fit: cover;
}

.lightbox-play-icon {
  z-index: 2;
  color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.video-overlay-layer {
  z-index: 1;
  background-color: rgba(0, 0, 0, .1);
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.features-component {
  grid-column-gap: 5rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  display: grid;
}

.features-content-left {
  position: -webkit-sticky;
  position: sticky;
  top: 30%;
}

.features-content-item {
  border: 1px solid var(--border--strokes);
  border-radius: var(--border--radius-small);
  background-color: var(--background-colors--secondary);
  background-image: linear-gradient(to bottom, var(--background-colors--secondary), var(--background-colors--tertiary));
  margin-bottom: 2rem;
  padding: 2rem;
  position: -webkit-sticky;
  position: sticky;
  overflow: hidden;
}

.features-content-item.content-item-1 {
  top: 30%;
}

.features-content-item.content-item-2 {
  top: 32%;
}

.layout-1-component {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
}

.layout-1-row {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: stretch;
  display: grid;
}

.layout-1-card {
  border: 1px solid var(--border--strokes);
  border-radius: var(--border--radius-small);
  background-color: var(--background-colors--primary);
  background-image: linear-gradient(to bottom, var(--background-colors--secondary), var(--background-colors--tertiary));
  flex-direction: column;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: stretch;
  display: flex;
  position: relative;
  overflow: hidden;
}

.layout-circle-1 {
  z-index: 0;
  width: 100%;
  height: 100%;
  border-radius: 0;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.layout-1-image-wrappers {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3.8rem;
  display: flex;
  position: relative;
}

.left-padding {
  padding-left: 2rem;
}

.integration-icon-wrapper {
  grid-column-gap: 1rem;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.integration-icon {
  width: 5.5rem;
  height: 5.5rem;
  border: 1px solid var(--border--strokes-2);
  background-color: var(--background-colors--secondary);
  border-radius: 5rem;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  display: flex;
}

.right-padding {
  padding-right: 2rem;
}

.layout-1-image-wrapper {
  width: 100%;
  max-width: 99.758%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 1.2rem;
  display: flex;
  position: relative;
}

.testimonial-component {
  column-count: 3;
  column-gap: 2rem;
}

.testimonial-content {
  width: 100%;
  border: 1px solid var(--border--strokes);
  border-radius: var(--border--radius-small);
  background-color: var(--background-colors--secondary);
  background-image: linear-gradient(to bottom, var(--background-colors--secondary), var(--background-colors--tertiary));
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 2rem;
  padding: 2rem;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.testimonial-client {
  text-align: left;
  align-items: center;
  display: flex;
}

.testimonial-client-image-wrapper {
  margin-right: 1rem;
}

.testimonial-customer-image {
  width: 3rem;
  height: 3rem;
  min-height: 3rem;
  min-width: 3rem;
  object-fit: cover;
  border-radius: 100%;
}

.blog-component {
  flex-direction: column;
  align-items: center;
}

.blog-collection-list {
  grid-column-gap: 2rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.blog-image-link {
  width: 100%;
  border: 1px solid var(--border--strokes);
  border-radius: var(--border--radius-small);
  margin-bottom: 1.5rem;
  overflow: hidden;
}

.blog-image-wrapper {
  width: 100%;
  padding-top: 66%;
  position: relative;
  overflow: hidden;
}

.blog-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.blog-category-link {
  margin-bottom: .5rem;
  margin-right: 1rem;
  font-size: .875rem;
  font-weight: 600;
  text-decoration: none;
}

.blog-category-link.w--current {
  border-bottom: 2px solid #000;
}

.blog-title-link {
  margin-bottom: .5rem;
  text-decoration: none;
  display: block;
}

.blog-author-wrapper {
  align-items: center;
  margin-top: 1.5rem;
  display: flex;
}

.blog-author-image-wrapper {
  margin-right: 1rem;
}

.blog-author-image {
  width: 3rem;
  height: 3rem;
  min-height: 3rem;
  min-width: 3rem;
  object-fit: cover;
  border-radius: 100%;
}

.blog-date-wrapper {
  align-items: center;
  display: flex;
}

.blog-text-divider {
  margin-left: .5rem;
  margin-right: .5rem;
}

.time-to-read {
  grid-column-gap: 6px;
  display: flex;
}

.faq-list {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-template-rows: auto;
  grid-template-columns: 100%;
  grid-auto-columns: 100%;
  align-items: start;
  justify-items: stretch;
  display: grid;
}

.faq-accordion {
  border: 1px solid var(--border--strokes);
  border-radius: var(--border--radius-small);
  background-color: var(--background-colors--primary);
  background-image: linear-gradient(to bottom, var(--background-colors--secondary), var(--background-colors--tertiary));
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.faq-question {
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 1.5rem;
  display: flex;
}

.faq-icon {
  width: 2rem;
  min-width: 2rem;
  color: var(--icon--icon);
  align-self: flex-start;
  margin-left: 1.5rem;
  display: flex;
}

.faq-answer {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  overflow: hidden;
}

.cta-content {
  z-index: 99;
  position: relative;
}

.footer-newsletter-wrapper {
  border: 1px solid var(--border--strokes);
  border-radius: var(--border--radius-small);
  background-color: var(--background-colors--secondary);
  background-image: linear-gradient(to bottom, var(--background-colors--secondary), var(--background-colors--tertiary));
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 5rem;
  padding: 2rem 1.5rem;
  display: flex;
}

.footer-form-block {
  max-width: 35rem;
  min-width: 25rem;
  margin-bottom: 0;
}

.footer-form {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr max-content;
  grid-auto-columns: 1fr;
  margin-bottom: .75rem;
  display: grid;
}

.success-message {
  background-color: var(--border--strokes);
  color: var(--font-colors--heading-primary);
  padding: 1.5rem;
}

.error-message {
  color: #fff;
  background-color: #ffb8b8;
  margin-top: 1.5rem;
  padding: .875rem 1rem;
}

.footer-top-wrapper {
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  grid-template-rows: auto;
  grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr;
  align-items: start;
}

.footer-logo-link {
  padding-left: 0;
}

.footer-link-list {
  flex-direction: column;
  grid-template-rows: auto;
  grid-template-columns: 100%;
  grid-auto-columns: 100%;
  align-items: flex-start;
  justify-items: start;
  display: flex;
}

.footer-link {
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-size: .875rem;
  text-decoration: none;
  transition: color .2s;
}

.footer-link:hover {
  color: var(--font-colors--link-hover);
}

.line-divider {
  width: 100%;
  height: .5px;
  background-color: var(--border--strokes);
}

.footer-bottom-wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.footer-legal-list {
  grid-column-gap: 1.5rem;
  grid-row-gap: 0rem;
  white-space: normal;
  grid-template-rows: auto;
  grid-template-columns: max-content;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  justify-content: center;
  display: flex;
}

.footer-credit-text {
  font-size: .875rem;
}

.footer-legal-link {
  font-size: .875rem;
  text-decoration: underline;
  transition: color .2s;
}

.footer-legal-link:hover {
  color: var(--font-colors--link-hover);
}

.footer-social-icons {
  grid-column-gap: .5rem;
  grid-row-gap: .5rem;
  white-space: normal;
  grid-template-rows: auto;
  grid-template-columns: max-content;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  align-items: start;
  justify-items: start;
  display: grid;
}

.social-link {
  border: 1px solid var(--border--strokes);
  border-radius: var(--border--radius-small);
  color: var(--icon--icon);
  background-color: rgba(0, 0, 0, 0);
  align-items: center;
  padding: .5rem;
  font-size: 14px;
  text-decoration: none;
  transition: background-color .2s;
  display: flex;
}

.social-link:hover,
.section-header-secondary {
  background-color: var(--background-colors--secondary);
}

.style-guide-content-layout {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 2fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.style-guide-menu {
  position: -webkit-sticky;
  position: sticky;
  top: 6rem;
}

.style-guide-menu-link-item {
  flex-direction: column;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 12px;
  text-decoration: none;
  display: flex;
}

.heading-medium {
  font-size: 2rem;
  line-height: 1.3;
}

.style-guide-content-list {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.style-guide-content-item {
  border: 1px solid var(--border--strokes);
  border-radius: var(--border--radius-small);
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.style-guide-color-wrapper {
  border-bottom: 1px solid var(--border--strokes);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-top: 75%;
}

.style-guide-color-wrapper.color-1 {
  border-bottom-color: var(--border--strokes);
  background-color: #fff;
}

.style-guide-color-wrapper.color-2 {
  background-color: #000;
}

.style-guide-color-wrapper.color-4 {
  background-color: var(--background-colors--gradient-1);
}

.style-guide-color-wrapper.color-6 {
  background-color: var(--background-colors--gradient-2);
}

.style-guide-color-wrapper.color-8 {
  background-color: var(--background-colors--primary);
  color: #1d2127;
}

.style-guide-color-wrapper.color-9 {
  background-color: var(--background-colors--secondary);
  color: #1d2127;
}

.style-guide-color-wrapper.color-10 {
  background-color: var(--background-colors--tertiary);
  color: #1d2127;
}

.style-guide-color-wrapper.color-11 {
  background-color: var(--font-colors--heading-primary);
}

.style-guide-color-wrapper.color-12 {
  background-color: var(--font-colors--heading-gradient-1);
}

.style-guide-color-wrapper.color-13 {
  background-color: var(--border--strokes-2);
}

.style-guide-color-wrapper.color-14 {
  background-color: var(--font-colors--paragraph);
}

.style-guide-content {
  padding: 10px;
}

.style-guide-content-list-wrapper {
  border: 1px solid var(--border--strokes);
  border-radius: var(--border--radius-small);
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding: 1rem;
  display: block;
}

.license-component,
.instruction-component {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.ellipse-2 {
  width: 10rem;
  height: 10rem;
  background-color: var(--background-colors--gradient-2);
  filter: blur(40px);
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: auto;
}

.about-story-component {
  grid-column-gap: 5rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  display: grid;
}

.about-story-timeline-wrapper {
  position: relative;
}

.about-story-progress-bar-wrapper {
  width: 1px;
  height: 75%;
  position: absolute;
  top: 10%;
  left: 2.2rem;
  right: auto;
}

.about-story-progress-bar {
  width: 1px;
  height: 100%;
  background-color: var(--border--strokes);
}

.about-story-timeline-step {
  grid-column-gap: 2.5rem;
  grid-row-gap: 0px;
  grid-template-rows: auto;
  grid-template-columns: max-content 1fr;
}

.about-story-timeline-left {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  display: flex;
  position: relative;
}

.about-story-timeline-icon-wrapper {
  z-index: 1;
  width: auto;
  border: 1px solid var(--border--strokes);
  background-color: var(--background-colors--secondary);
  border-radius: 5px;
  margin-left: -4px;
  padding: 1rem;
  position: relative;
}

.about-story-timeline-right {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.beliefs-component {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.beliefs-list {
  grid-column-gap: 3rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  justify-items: center;
  display: grid;
}

.beliefs-item {
  width: 100%;
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.beliefs-image-wrapper {
  width: 100%;
  border-radius: 5px;
  padding: 1rem;
  position: relative;
  overflow: hidden;
}

.team-list {
  grid-column-gap: 2rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  justify-items: start;
  display: grid;
}

.team-item {
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.team-link {
  text-decoration: none;
}

.team-image {
  width: 100%;
  min-height: 10rem;
  min-width: 10rem;
  border: 1px solid var(--border--strokes);
  object-fit: cover;
}

.team-social {
  grid-column-gap: .875rem;
  grid-row-gap: .875rem;
  grid-template-rows: auto;
  grid-template-columns: max-content;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  align-self: flex-start;
  margin-top: 1.5rem;
  display: grid;
}

.team-social-link {
  border: 1px solid var(--border--strokes);
  border-radius: var(--border--radius-small);
  background-color: var(--background-colors--primary);
  padding: .5rem;
  transition: background-color .2s;
}

.team-social-link:hover {
  background-color: var(--background-colors--secondary);
}

.pricing-component {
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  display: flex;
}

.pricing-tabs-menu {
  border: 1px solid var(--border--strokes-2);
  border-radius: var(--border--radius-small);
  background-color: var(--background-colors--secondary);
  align-self: center;
  margin-bottom: 3rem;
  display: flex;
  overflow: hidden;
}

.pricing-tab-link {
  color: var(--button-secondary--text);
  background-color: rgba(0, 0, 0, 0);
  padding: .5rem 1.5rem;
}

.pricing-tab-link.w--current {
  background-color: var(--button-primary--background);
  color: var(--background-colors--primary);
}

.pricing-plans {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
}

.pricing-plan {
  height: 100%;
  border: 1px solid var(--border--strokes);
  border-radius: var(--border--radius-small);
  background-image: linear-gradient(to bottom, var(--background-colors--secondary), var(--background-colors--tertiary));
  padding: 2rem;
  position: relative;
  overflow: hidden;
}

.pricing-content {
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
}

.pricing-divider {
  width: 100%;
  height: .5px;
  border: 1px solid var(--border--strokes);
  background-color: var(--swatch--charcoal\<deleted\|variable-7ce553ac-38bb-d015-a0dc-c7f61c56011f\>);
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.pricing-feature-list {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  padding-top: .5rem;
  padding-bottom: .5rem;
  display: grid;
}

.pricing-feature {
  display: flex;
}

.pricing-feature-icon-wrapper {
  flex: none;
  align-self: flex-start;
  margin-right: 1rem;
}

.pricing-center-block {
  border-radius: var(--border--radius-small);
  background-image: linear-gradient(to bottom, var(--background-colors--gradient-2), var(--background-colors--gradient-1));
  padding: 2px;
}

.pricing-compare-component {
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  display: flex;
}

.pricing-compare-tabs-menu {
  border: 1px solid var(--button-secondary--border);
  border-radius: var(--border--radius-small);
  background-color: var(--background-colors--secondary);
  align-self: center;
  margin-bottom: 5rem;
  display: flex;
  overflow: hidden;
}

.pricing-compare-tab-link {
  color: var(--button-secondary--text);
  background-color: rgba(0, 0, 0, 0);
  padding: .5rem 1.5rem;
}

.pricing-compare-tab-link.w--current {
  background-color: var(--button-primary--background);
  color: var(--button-primary--text);
}

.pricing-compare-tabs-content,
.pricing-compare-plans {
  width: 100%;
}

.pricing-compare-top-row {
  grid-column-gap: 2rem;
  grid-row-gap: 0px;
  background-color: rgba(0, 0, 0, 0);
  grid-template-rows: auto;
  grid-template-columns: 1.5fr 1fr 1fr 1fr;
}

.pricing-compare-top-row-content {
  height: 100%;
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.pricing-compare-top-row-price {
  color: var(--font-colors--heading-primary);
  font-size: 3.5rem;
  font-weight: 400;
  line-height: 1.2;
}

.pricing-compare-heading-row {
  margin-top: 2rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.pricing-compare-row {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background-color: var(--background-colors--primary);
  border: 1px #151a25;
  grid-template-rows: auto;
  grid-template-columns: 1.5fr 1fr 1fr 1fr;
  align-items: stretch;
}

.pricing-compare-row.background-color-secondary {
  background-color: var(--background-colors--secondary);
  border-style: none;
}

.pricing-compare-feature {
  padding: 1rem 1.5rem;
}

.pricing-compare-row-content {
  text-align: center;
  border-left: 1px #1f2123;
  border-right: 1px #1f2123;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.5rem;
  font-weight: 600;
  display: flex;
}

.pricing-compare-bottom-row {
  grid-column-gap: 2rem;
  grid-row-gap: 0px;
  background-color: rgba(0, 0, 0, 0);
  grid-template-rows: auto;
  grid-template-columns: 1.5fr 1fr 1fr 1fr;
  padding-top: 2rem;
}

.pricing-compare-bottom-row-content {
  height: 100%;
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.logo1-component {
  grid-column-gap: 2.75rem;
  grid-row-gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: .5rem;
  padding-bottom: .5rem;
  display: flex;
}

.logo1-logo {
  width: 8rem;
  max-height: 3.5rem;
}

.testimonial-single-component {
  grid-column-gap: 5rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  justify-items: center;
  display: grid;
}

.testimonial-single-client-image {
  object-fit: cover;
}

.testimonial-single-content {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.testimonial-single-rating-wrapper {
  display: flex;
}

.testimonial-single-rating-icon {
  width: 1.3rem;
  color: var(--icon--icon);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: .25rem;
  display: flex;
}

.testimonial-single-client {
  text-align: left;
  align-items: center;
  display: flex;
}

.testimonial-single-divider {
  width: 1px;
  background-color: #333a44;
  align-self: stretch;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.testimonial-single-logo {
  max-height: 3rem;
}

.section-page-header {
  position: relative;
  overflow: hidden;
}

.values-component {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.values-content-bottom {
  grid-column-gap: 3rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  justify-items: start;
  display: grid;
}

.values-item {
  width: 100%;
  grid-column-gap: 0rem;
  grid-row-gap: 0rem;
  text-align: center;
  flex-direction: column;
  grid-template-rows: min-content min-content min-content;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: block;
}

.career-icon-block {
  border: 1px solid var(--border--strokes);
  border-radius: var(--border--radius-small);
  background-color: var(--background-colors--secondary);
  padding: 1rem;
  display: inline-block;
}

.career-component {
  margin-bottom: 2rem;
  padding-left: 0;
}

.career-accordion {
  border: 1px solid var(--border--strokes);
  background-color: var(--background-colors--secondary);
  background-image: linear-gradient(to bottom, var(--background-colors--secondary), var(--background-colors--tertiary));
  border-radius: .5rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: .5rem 2rem;
}

.career-job-department {
  cursor: pointer;
  border-top: 1px #000;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  display: flex;
}

.arrow-icon {
  width: 2rem;
  color: var(--icon--icon);
  align-self: center;
  margin-left: 1.5rem;
  display: flex;
}

.career-list-wrapper {
  overflow: hidden;
}

.career-list {
  margin-bottom: 2rem;
  display: block;
}

.carreer-icon-wrapper {
  display: inline-block;
}

.career-item {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.career-title-wrapper {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.change-log-component {
  grid-column-gap: 5rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: .5fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  display: grid;
}

.change-log-date {
  border: 1px solid var(--border--strokes);
  background-color: var(--background-colors--secondary);
  padding: 1rem;
  position: -webkit-sticky;
  position: sticky;
  top: 5rem;
}

.content {
  margin-bottom: 4rem;
}

.contact-header-component {
  grid-column-gap: 3rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  justify-items: center;
  display: grid;
}

.contact-header-item {
  width: 100%;
  text-align: center;
}

.contact-icon-block {
  border: 1px solid var(--border--strokes);
  border-radius: var(--border--radius-small);
  background-color: var(--background-colors--tertiary);
  padding: 1.1rem;
  display: inline-block;
}

.heading-medium-text {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.3;
}

.contact-component {
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 0;
}

.contact-form {
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.form-field-2col {
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.form-radio-2col {
  grid-column-gap: 1.5rem;
  grid-row-gap: .875rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.success-text {
  font-weight: 600;
}

.error-text {
  color: #912222;
}

.contact-locations-component {
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  justify-items: stretch;
  display: grid;
}

.contact-locations-location {
  width: 100%;
  text-align: center;
}

.contact-locations-image-wrapper {
  width: 100%;
  padding-top: 66%;
  position: relative;
}

.contact-locations-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.section-layout-card {
  position: relative;
}

.card-component {
  flex-direction: column;
  align-items: center;
  display: flex;
}

._2-column-list {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.card-item {
  width: 100%;
  border: 1px solid var(--border--strokes);
  border-radius: var(--border--radius-small);
  background-color: var(--background-colors--secondary);
  background-image: linear-gradient(163deg, var(--background-colors--secondary), var(--background-colors--tertiary));
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem;
  transition: background-color .2s;
  display: flex;
  position: relative;
  overflow: hidden;
}

.icon-wrapper {
  padding: .5rem;
  display: inline-block;
}

._3-column-list {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.section-login {
  position: relative;
  overflow: hidden;
}

.login-component {
  min-height: 100svh;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding-top: 5rem;
  padding-bottom: 5rem;
  display: flex;
  position: relative;
  overflow: auto;
}

.navbar-2 {
  width: 100%;
  height: 4.5rem;
  border-radius: var(--border--radius-small);
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.block-wrapper {
  border: 1px solid var(--border--strokes);
  border-radius: var(--border--radius-small);
  background-color: var(--background-colors--primary);
  background-image: linear-gradient(to bottom, var(--background-colors--secondary), var(--background-colors--tertiary));
  padding: 2rem;
}

.login-form-block {
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 0;
}

.login-form {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.form-button-wrapper {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.login-footer {
  width: 100%;
  height: 4.5rem;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.section-signup {
  position: relative;
}

.signup-component {
  min-height: 100svh;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding-top: 5rem;
  padding-bottom: 5rem;
  display: flex;
  position: relative;
  overflow: auto;
}

.signup-form-block {
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 0;
}

.signup-form {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-template-rows: auto auto auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.signup-footer {
  width: 100%;
  height: 4.5rem;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.section-cta {
  perspective: 1000px;
  position: relative;
  overflow: hidden;
}

._1-column-list {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.integration-content {
  grid-column-gap: 5rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr .5fr;
  grid-auto-columns: 1fr;
  align-items: start;
  display: grid;
}

.content-name {
  grid-column-gap: 2rem;
  display: flex;
}

.content-logo {
  width: 3rem;
}

.content-logo.partner,
.content-logo.customer {
  width: 10rem;
}

.application-details {
  border: 1px solid var(--border--strokes);
  background-color: var(--background-colors--secondary);
  padding: 2rem;
  position: -webkit-sticky;
  position: sticky;
  top: 5rem;
  bottom: 5rem;
}

.job-content-metatag-list {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.job-content-metatag-item {
  border: 1px solid var(--border--strokes);
  background-color: var(--background-colors--secondary);
  text-align: center;
  padding: 2rem;
}

.content-component {
  grid-column-gap: 5rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  display: grid;
}

.content-form {
  margin-bottom: 0;
}

.content-form-wrapper {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  margin-bottom: 1rem;
  display: grid;
}

.user-content-component {
  grid-column-gap: 5rem;
  grid-row-gap: 5rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  display: grid;
}

.user-image-wrapper {
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex: 1;
  align-items: center;
  display: flex;
  position: relative;
}

.user-image {
  width: 100%;
  border-radius: 20px;
}

.team-social-icons {
  grid-column-gap: .75rem;
  grid-row-gap: 0rem;
  white-space: normal;
  background-color: rgba(0, 0, 0, 0);
  grid-template-rows: auto;
  grid-template-columns: max-content;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  align-items: start;
  justify-items: center;
  padding: .4rem .8rem;
  display: grid;
  position: static;
  top: 2%;
  bottom: auto;
  left: auto;
  right: 3%;
}

.blog-post-header-title-wrapper {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 5rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.blog-post-header1-breadcrumb {
  width: 100%;
  color: #a0a7b3;
  align-items: center;
  margin-bottom: 1.5rem;
  display: flex;
}

.breadcrumb-link {
  color: #ecf15e;
  text-decoration: none;
}

.breadcrumb-link.w--current {
  font-weight: 600;
}

.breadcrumb-divider {
  width: 1rem;
  height: 1rem;
  justify-content: center;
  align-items: center;
  margin-left: .5rem;
  margin-right: .5rem;
  display: flex;
}

.blog-post-header-content-top {
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

.blog-post-header-author-wrapper {
  align-items: center;
  display: flex;
}

.blog-post-header-author-image-wrapper {
  margin-right: 1rem;
}

.blog-post-header-author-image {
  width: 3.5rem;
  height: 3.5rem;
  min-height: 3.5rem;
  min-width: 3.5rem;
  object-fit: cover;
  border-radius: 100%;
}

.blog-post-header-date-wrapper {
  margin-top: .25rem;
  display: flex;
}

.blog-post-header-text-divider {
  margin-left: .5rem;
  margin-right: .5rem;
}

.blog-post-header-share {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-template-rows: auto;
  grid-template-columns: max-content;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  justify-items: start;
  margin-top: 1rem;
  display: grid;
}

.blog-post-header-social-link {
  border: 1px solid var(--border--strokes);
  border-radius: var(--border--radius-small);
  padding: .5rem;
  transition: background-color .2s;
}

.blog-post-header-social-link:hover {
  background-color: var(--border--strokes);
}

.blog-post-header-image-wrapper {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 50%;
  position: relative;
  overflow: hidden;
}

.blog-post-header1-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.blog-content {
  margin-bottom: 4rem;
}

.blog-content-bottom {
  justify-content: center;
  align-items: flex-end;
  display: flex;
}

.blog-share-wrapper {
  width: 50%;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.blog-divider {
  width: 100%;
  height: 1px;
  max-width: 48rem;
  background-color: var(--border--strokes);
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.blog-1-header-category-menu {
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
  display: flex;
}

.blog-1-header-category-menu.no-scrollbar {
  grid-column-gap: 1rem;
  align-items: flex-start;
}

.blog-1-header-menu-link {
  border: 1px solid var(--border--strokes);
  background-color: var(--background-colors--secondary);
  white-space: nowrap;
  flex: none;
  padding: .5rem 1rem;
  text-decoration: none;
  transition: background-color .2s;
}

.blog-1-header-menu-link:hover {
  background-color: var(--border--strokes);
}

.empty-state {
  background-color: var(--swatch--charcoal\<deleted\|variable-7ce553ac-38bb-d015-a0dc-c7f61c56011f\>);
}

.section-coming-soon {
  position: relative;
}

.benefit-list {
  grid-column-gap: 3rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  justify-items: center;
  display: grid;
}

.benefit-item {
  width: 100%;
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.benefit-component {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.event-image-link {
  width: 100%;
  border: 1px solid var(--border--strokes);
  border-radius: var(--border--radius-small);
  margin-bottom: 1.5rem;
  overflow: hidden;
}

.event-image-wrapper {
  width: 100%;
  padding-top: 66%;
  position: relative;
  overflow: hidden;
}

.event-images {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.event-time-wrapper {
  margin-top: 7px;
  display: flex;
}

.event-date-divided {
  margin-left: .5rem;
  margin-right: .5rem;
}

.event-content-metatag-list {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  grid-template-rows: auto;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.event-content-metatag-item {
  border: 1px solid var(--border--strokes);
  background-color: var(--background-colors--secondary);
  text-align: center;
  padding: 2rem;
}

.media-content {
  grid-column-gap: 5rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr .5fr;
  grid-auto-columns: 1fr;
  align-items: start;
  display: grid;
}

.media-name {
  grid-column-gap: 2rem;
  display: flex;
}

.media-logo {
  width: 10rem;
}

.navbar-menu-button {
  padding: 0;
}

.footer-logo-paragraph {
  width: 80%;
  font-weight: 900;
}

.footer-logo-paragraph.text-color-gradient {
  font-family: var(--fonts-family--heading-font);
  font-weight: 900;
}

.event-collection-list {
  grid-column-gap: 2rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.event-component {
  flex-direction: column;
  align-items: center;
}

.section-forgot-password {
  position: relative;
}

.forgot-password-footer {
  width: 100%;
  height: 4.5rem;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.forgot-password-form-block {
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 0;
}

.forgot-password-form {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-template-rows: auto auto auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.icon-embed-medium {
  width: 3rem;
  height: 3rem;
  color: var(--icon--icon);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.text-block {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  display: flex;
}

.event-register {
  border: 1px solid var(--border--strokes);
  border-radius: var(--border--radius-small);
  background-color: var(--background-colors--primary);
  background-image: linear-gradient(to bottom, var(--background-colors--secondary), var(--background-colors--tertiary));
  padding: 2rem;
  position: -webkit-sticky;
  position: sticky;
  top: 5rem;
  bottom: 5rem;
}

.integration-details {
  border: 1px solid var(--border--strokes);
  border-radius: var(--border--radius-small);
  background-color: var(--background-colors--secondary);
  padding: 2rem;
  position: -webkit-sticky;
  position: sticky;
  top: 5rem;
  bottom: 5rem;
}

.customer-details {
  border: 1px solid var(--border--strokes);
  background-color: var(--background-colors--secondary);
  padding: 2rem;
  position: -webkit-sticky;
  position: sticky;
  top: 5rem;
  bottom: 5rem;
}

.job-application {
  border: 1px solid var(--border--strokes);
  border-radius: var(--border--radius-small);
  background-color: var(--background-colors--primary);
  background-image: linear-gradient(to bottom, var(--background-colors--secondary), var(--background-colors--tertiary));
  padding: 2rem;
  position: -webkit-sticky;
  position: sticky;
  top: 5rem;
  bottom: 5rem;
}

.section-header-1 {
  position: relative;
  overflow: hidden;
}

.header-2-component {
  grid-column-gap: 5rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
  position: relative;
}

.header-2-image-wrapper {
  border: 1px solid var(--border--strokes);
  border-radius: var(--border--radius-small);
  background-color: var(--background-colors--primary);
  padding: .125rem;
  position: relative;
  overflow: hidden;
}

.feature-1-list {
  grid-column-gap: 3rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  justify-items: center;
  display: grid;
}

.features-1-item {
  width: 100%;
  height: 100%;
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.feature-1-icon-wrapper {
  border: 1px solid var(--border--strokes);
  border-radius: var(--border--radius-small);
  background-image: linear-gradient(to bottom, var(--background-colors--secondary), var(--background-colors--primary));
  padding: 1rem;
  display: inline-block;
}

.tab-component {
  flex-direction: column;
  display: flex;
}

.tabs-menu {
  margin-bottom: 4rem;
  display: flex;
}

.tab-link {
  width: 100%;
  grid-column-gap: .25rem;
  grid-row-gap: .25rem;
  color: var(--font-colors--link);
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 1.5px solid rgba(0, 0, 0, 0);
  flex-direction: column;
  padding: 1rem 1.5rem 2rem;
  display: flex;
}

.tab-link.w--current {
  border-bottom-color: var(--border--strokes-2);
  background-color: rgba(0, 0, 0, 0);
}

.features-tab-wrapper {
  width: 100%;
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;
  flex-direction: column;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
}

.tab-image-wrapper {
  width: 100%;
  border: 1px solid var(--border--strokes);
  border-radius: var(--border--radius-small);
  overflow: hidden;
}

.tab-image {
  width: 100%;
  height: 100%;
}

.results-component {
  grid-column-gap: 5rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.results-item-list {
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  grid-template-rows: auto;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.results-image-wrapper {
  border-radius: var(--border--radius-small);
  padding: .125rem;
  position: relative;
  overflow: hidden;
}

.results-image {
  width: 100%;
  height: 100%;
  border: 1px solid var(--border--strokes);
  border-radius: var(--border--radius-small);
}

.layout-2-component {
  grid-column-gap: 5rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.layout-2-tabs-link {
  border-left: 1px solid var(--border--strokes);
  color: var(--font-colors--link);
  background-color: rgba(0, 0, 0, 0);
  margin-top: 0;
  margin-bottom: 0;
  padding: 2rem;
  display: block;
}

.layout-2-tabs-link.w--current {
  border-left-color: var(--border--strokes-2);
  background-color: var(--background-colors--secondary);
  opacity: 1;
}

.layout-2-image-wrapper {
  max-width: 33rem;
  border: 1px solid var(--border--strokes);
  border-radius: var(--border--radius-small);
}

.features-8-image {
  width: 100%;
  height: 100%;
}

.features-lightbox {
  width: 100%;
  max-width: 33rem;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.features-lightbox-image {
  object-fit: cover;
}

.testimonial-2-component {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.testimonial-2-logo {
  max-height: 3.5rem;
}

.testimonial-2-client-image-wrapper {
  margin-bottom: 1rem;
}

.testimonial-2-client-image {
  width: 4rem;
  height: 4rem;
  min-height: 4rem;
  min-width: 4rem;
  object-fit: cover;
  border-radius: 100%;
}

.faq-1-component {
  border-bottom: 1px solid var(--border--strokes);
}

.faq-1-accordion {
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.faq-1-question {
  border-top: 1px solid var(--border--strokes);
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  display: flex;
}

.faq-1-icon {
  width: 2rem;
  min-width: 2rem;
  color: var(--icon--icon);
  align-self: flex-start;
  margin-left: 1.5rem;
  display: flex;
}

.faq-1-answer {
  overflow: hidden;
}

.section-header-3.background-line {
  position: relative;
  overflow: hidden;
}

.header-3-component {
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.header-3-lightbox {
  width: 100%;
  border-radius: var(--border--radius-small);
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.header-3-lightbox-image {
  object-fit: cover;
}

.features-7-component {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.features-7-list {
  grid-column-gap: 2rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  justify-items: center;
  display: grid;
}

.layout-3-component {
  grid-column-gap: 5rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.features-3-item-list {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  padding-top: .5rem;
  padding-bottom: .5rem;
  display: grid;
}

.features-3-item {
  display: flex;
}

.features-3-item-icon-wrapper {
  background-color: var(--border--strokes);
  border-radius: 4px;
  flex: none;
  align-self: flex-start;
  margin-right: 1rem;
  padding: .125rem;
}

.layout-video-component {
  grid-column-gap: 5rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.layout-video-lightbox {
  width: 100%;
  border: 1px solid var(--border--strokes);
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.layout-video-lightbox-image {
  width: 100%;
  object-fit: cover;
}

.layout-4-component {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
}

.layout-4-row {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
}

.layout-4-card-large {
  border: 1px solid var(--border--strokes);
  background-color: var(--background-colors--secondary);
  border-radius: 10px;
  flex-direction: column;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: stretch;
  display: flex;
}

.layout-4-card-large-image-wrapper {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.layout-4-card-large-content {
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  align-items: stretch;
  padding: 3rem;
  display: flex;
}

.layout-4-card-small {
  border: 1px solid var(--border--strokes);
  background-color: var(--background-colors--secondary);
  border-radius: 10px;
  flex-direction: column;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: stretch;
  display: flex;
}

.layout-4-card-small-content {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  padding: 3rem;
  display: flex;
}

.section-testimonial-3 {
  overflow: hidden;
}

.testimonial-3-component {
  height: auto;
  background-color: rgba(0, 0, 0, 0);
  padding-bottom: 4rem;
  padding-left: .875rem;
  padding-right: .875rem;
  overflow: hidden;
}

.testimonial-3-mask {
  width: 33.33%;
  overflow: visible;
}

.testimonial-3-slide {
  padding-left: 1rem;
  padding-right: 1rem;
}

.testimonial-3-content {
  width: 100%;
  border: 1px solid var(--border--strokes);
  border-radius: var(--border--radius-small);
  background-color: var(--background-colors--secondary);
  background-image: linear-gradient(to bottom, var(--background-colors--secondary), var(--background-colors--tertiary));
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 2rem;
  display: flex;
}

.testimonial-3-client {
  text-align: left;
  align-items: center;
  display: flex;
}

.testimonial-3-client-image-wrapper {
  margin-right: 1rem;
}

.testimonial-3-customer-image {
  width: 3rem;
  height: 3rem;
  min-height: 3rem;
  min-width: 3rem;
  object-fit: cover;
  border-radius: 100%;
}

.testimonial-3-arrow {
  width: 3.5rem;
  height: 3.5rem;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  bottom: 4rem;
}

.testimonial-3-arrow.hide-mobile-landscape {
  border-color: var(--border--strokes);
  background-color: var(--background-colors--secondary);
}

.testimonial-3-arrow-icon {
  width: 1.5rem;
  height: 1.5rem;
  color: var(--icon--icon);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.testimonial-3-slide-nav {
  height: 1.75rem;
  font-size: .5rem;
}

.faq-2-component {
  grid-column-gap: 5rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: .75fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  display: grid;
}

.faq-2-list-grid {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-template-rows: auto;
  grid-template-columns: 100%;
  grid-auto-columns: 100%;
  align-items: start;
  justify-items: stretch;
  display: grid;
}

.faq-2-accordion {
  border: 1px solid var(--border--strokes);
  border-radius: var(--border--radius-small);
  background-color: var(--background-colors--secondary);
  background-image: linear-gradient(to bottom, var(--background-colors--secondary), var(--background-colors--tertiary));
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.faq-2-question {
  cursor: pointer;
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 1.5rem;
  display: flex;
}

.faq-2-icon {
  width: 2rem;
  min-width: 2rem;
  align-self: flex-start;
  margin-left: 1.5rem;
  display: flex;
}

.faq-2-answer {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  overflow: hidden;
}

.features-7-item {
  width: 100%;
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.story-1-component {
  grid-column-gap: 5rem;
  grid-row-gap: 5rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  display: grid;
}

.story-1-content-left {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.story-1-content-right {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  display: flex;
}

.story-1-image-wrapper {
  border-radius: 10px;
  overflow: hidden;
}

.growth-1-component {
  grid-column-gap: 5rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.growth-1-item-list {
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  grid-template-rows: auto;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.growth-1-image-wrapper {
  border: 1px solid var(--border--strokes);
}

.values-1-component {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.values-1-list {
  grid-column-gap: 3rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  justify-items: center;
  display: grid;
}

.values-1-item {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.team-1-list {
  grid-column-gap: 2rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  justify-items: center;
  display: grid;
}

.team-1-item {
  text-align: center;
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.about-1-team-link {
  text-decoration: none;
}

.team-1-image {
  width: 10rem;
  height: 10rem;
  min-height: 5rem;
  min-width: 5rem;
  border: 1px solid var(--border--strokes);
  object-fit: cover;
  border-radius: 100%;
}

.team-1-social {
  grid-column-gap: .875rem;
  grid-row-gap: .875rem;
  grid-template-rows: auto;
  grid-template-columns: max-content;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  align-self: center;
  margin-top: 1.5rem;
  display: grid;
}

.growth-3-component {
  grid-column-gap: 5rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  display: grid;
}

.growth-3-item-list {
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  grid-template-rows: auto;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.growth-2-image {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.values-2-component {
  flex-direction: column;
  align-items: flex-start;
  margin-top: 13px;
  display: flex;
}

.values-2-list {
  grid-column-gap: 3rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  justify-items: center;
  display: grid;
}

.values-2-item {
  text-align: left;
  flex-direction: column;
  align-items: flex-start;
}

.partners-component {
  grid-column-gap: 5rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.partners-list {
  grid-column-gap: .5rem;
  grid-row-gap: .5rem;
  grid-template-rows: auto;
}

.partners-wrapper {
  width: 100%;
  border: 1px solid var(--border--strokes);
  background-color: var(--background-colors--secondary);
  justify-content: center;
  align-items: flex-start;
  padding: .875rem;
  display: flex;
}

.partners-logo {
  max-height: 3.5rem;
  max-width: 9rem;
}

.team-2-list {
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  display: grid;
}

.team-2-item {
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.team-2-image-wrapper {
  width: 100%;
  border: 1px solid var(--border--strokes);
  border-radius: var(--border--radius-small);
  padding-top: 100%;
  position: relative;
  overflow: hidden;
}

.team-2-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.team-2-item-content {
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  display: flex;
}

.team-2-item-link {
  text-decoration: none;
}

.team-2-social {
  grid-column-gap: .875rem;
  grid-row-gap: .875rem;
  grid-template-rows: auto;
  grid-template-columns: max-content;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  align-self: flex-start;
  margin-top: 1.5rem;
  display: grid;
}

.pricing-1-component {
  grid-column-gap: 5rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.pricing-2-content-list {
  grid-column-gap: 1.5rem;
  grid-row-gap: 2rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.pricing-2-content-item {
  display: flex;
}

.pricing-2-item-icon-wrapper {
  flex: none;
  align-self: flex-start;
  margin-right: 1.5rem;
}

.pricing-2-item-text-wrapper {
  padding-top: 10px;
}

.pricing-1-plan {
  border: 1px solid var(--border--strokes);
  background-color: var(--background-colors--secondary);
  background-image: linear-gradient(to bottom, var(--background-colors--secondary), var(--background-colors--tertiary));
  border-radius: 5px;
  padding: 2rem;
  position: relative;
  overflow: hidden;
}

.pricing-1-plan-content {
  position: relative;
}

.pricing-1-content-top {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.pricing-1-divider {
  width: 100%;
  height: 1px;
  background-color: var(--border--strokes);
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.pricing-1-feature-list {
  grid-column-gap: 1.5rem;
  grid-row-gap: 1rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  margin-top: 1rem;
  margin-bottom: 2rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  display: grid;
}

.pricing-1-feature {
  display: flex;
}

.pricing-1-icon-wrapper {
  flex: none;
  align-self: flex-start;
  margin-right: 1rem;
}

.faq-3-component {
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  display: grid;
}

.faq-3-list {
  grid-column-gap: 3rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.pricing-header-2-component {
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  display: flex;
}

.pricing-header-2-tabs-menu {
  border: 1px solid var(--button-secondary--border);
  border-radius: var(--border--radius-small);
  background-color: var(--background-colors--secondary);
  align-self: center;
  margin-bottom: 3rem;
  display: flex;
  overflow: hidden;
}

.pricing-header-2-tab-link {
  background-color: var(--swatch--transparent);
  color: var(--button-secondary--text);
  padding: .5rem 1.5rem;
}

.pricing-header-2-tab-link.w--current {
  background-color: var(--button-primary--background);
  color: var(--background-colors--primary);
}

.pricing-header-2-plans {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  grid-template-rows: auto;
}

.pricing-header-2-plan {
  border: 1px solid var(--border--strokes);
  border-radius: var(--border--radius-small);
  background-color: var(--background-colors--secondary);
  background-image: linear-gradient(to bottom, var(--background-colors--secondary), var(--background-colors--tertiary));
  padding: 2rem;
  position: relative;
  overflow: hidden;
}

.pricing-header-2-content {
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  position: relative;
}

.pricing-header-2-content-top {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.pricing-header-3-icon-wrapper {
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  margin-bottom: 1rem;
  display: flex;
}

.pricing-header-2-top-right {
  flex-direction: column;
  align-items: flex-end;
  display: flex;
}

.pricing-header-2-divider {
  width: 100%;
  height: 1px;
  background-color: var(--border--strokes);
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.pricing-header-2-feature-list {
  grid-column-gap: 1.5rem;
  grid-row-gap: 1rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  margin-top: 1rem;
  margin-bottom: 2rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  display: grid;
}

.pricing-header-2-feature {
  display: flex;
}

.pricing-header-2-feature-icon-wrapper {
  flex: none;
  align-self: flex-start;
  margin-right: 1rem;
}

.testimonial-4-component {
  grid-column-gap: 3rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.testimonial-4-content {
  border: 1px solid var(--border--strokes);
  border-radius: var(--border--radius-small);
  background-color: var(--background-colors--secondary);
  background-image: linear-gradient(to bottom, var(--background-colors--secondary), var(--background-colors--tertiary));
  text-align: center;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  display: flex;
}

.testimonial-4-logo {
  max-height: 3.5rem;
}

.testimonial-4-client-image-wrapper {
  margin-bottom: 1rem;
}

.testimonial-4-client-image {
  width: 3.5rem;
  height: 3.5rem;
  min-height: 3.5rem;
  min-width: 3.5rem;
  object-fit: cover;
  border-radius: 100%;
}

.pricing-component-2 {
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  display: flex;
}

.pricing-2-tabs-menu {
  border: 1px solid var(--button-secondary--border);
  border-radius: var(--border--radius-small);
  background-color: var(--background-colors--secondary);
  align-self: center;
  margin-bottom: 5rem;
  display: flex;
  overflow: hidden;
}

.pricing-2-tab-link {
  color: var(--button-secondary--text);
  background-color: rgba(0, 0, 0, 0);
  padding: .5rem 1.5rem;
}

.pricing-2-tab-link.w--current {
  background-color: var(--button-primary--background);
  color: var(--button-primary--text);
}

.pricing-2-tabs-content {
  width: 100%;
  overflow: visible;
}

.pricing-2-comparison-component {
  width: 100%;
}

.pricing-2-comparison-top-row {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border-bottom: 1px solid var(--border--strokes);
  background-color: var(--background-colors--primary);
  grid-template-rows: auto;
  grid-template-columns: 1.5fr 1fr 1fr 1fr;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.pricing-3-comparison-top-row-content {
  height: 100%;
  border-left: 1px solid var(--border--strokes);
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 1.5rem;
  display: flex;
}

.pricing1-comparison-top-row-price-copy {
  color: var(--font-colors--heading-primary);
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 1.2;
}

.pricing-2-comparison-heading-row {
  border-bottom: 1px solid var(--border--strokes);
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.pricing-2-comparison-row {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border-bottom: 1px solid var(--border--strokes);
  background-color: var(--background-colors--primary);
  grid-template-rows: auto;
  grid-template-columns: 1.5fr 1fr 1fr 1fr;
  align-items: stretch;
}

.pricing-2-comparison-feature {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 1.5rem;
}

.pricing-2-comparison-row-content {
  border-left: 1px solid var(--border--strokes);
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.5rem;
  font-weight: 600;
  display: flex;
}

.pricing-2-comparison-row-content.is-first {
  border-left-color: var(--border--strokes);
}

.layout-1-card-content {
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  padding: 3rem;
  display: flex;
  position: relative;
}

.layout-3-image-wrapper {
  border: 1px solid var(--border--strokes);
  border-radius: var(--border--radius-small);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.home2-layout-item-icon-wrapper {
  background-color: var(--border--strokes);
  border-radius: 2px;
  flex: none;
  align-self: flex-start;
  margin-right: 1rem;
  padding: .125rem;
}

.home2-layout-item {
  display: flex;
}

.icon-embed-large {
  width: 5rem;
  height: 5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.icon-embed-xlarge {
  width: 6.5rem;
  height: 6.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.text-color-primary {
  color: var(--font-colors--heading-primary);
}

.header-2-image-block {
  border-radius: var(--border--radius-small);
  overflow: hidden;
}

.header-2-image,
.result-image-block {
  border-radius: var(--border--radius-small);
  position: relative;
  overflow: hidden;
}

.layout-2-image {
  overflow: hidden;
}

.background-wrapper {
  z-index: -2;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
}

.section-page-header-content {
  position: relative;
}

.background-gradient {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, var(--swatch--transparent), var(--swatch--transparent) 58%, var(--background-colors--primary) 62%, var(--background-colors--primary)), url('../images/Dot_1Dot.png'), linear-gradient(to bottom, var(--background-colors--gradient-1), var(--background-colors--gradient-2) 35%, var(--background-colors--primary) 60%);
  background-position: 0 0, 0 0, 0 0;
  background-size: auto, 20px, auto;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
  overflow: hidden;
}

.background-gradient.top-header {
  background-image: url('../images/Dot_1Dot.png'), linear-gradient(to bottom, var(--background-colors--gradient-1), var(--background-colors--gradient-2));
  background-position: 0 0, 0 0;
  background-size: 20px, auto;
}

.background-gradient-cta {
  width: 100%;
  height: 100%;
  background-image: url('../images/Dot_1Dot.png'), linear-gradient(to bottom, var(--background-colors--gradient-1), var(--background-colors--gradient-2));
  background-position: 0 0, 0 0;
  background-size: 20px, auto;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
  overflow: hidden;
}

.document-item {
  width: 100%;
  border-radius: var(--border--radius-small);
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2rem;
  transition: background-color .2s;
  display: flex;
  position: relative;
  overflow: hidden;
}

.document-heading {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  align-items: center;
  display: flex;
}

.docs-icon {
  width: 2rem;
}

.document-link-wrapper {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  align-items: center;
  margin-left: 0;
  text-decoration: none;
  display: flex;
}

.pointer {
  width: .5rem;
  height: .5rem;
  background-color: var(--icon--icon);
  border-radius: 1rem;
}

.docs-component {
  background-color: var(--background-colors--primary);
  flex-direction: column;
  align-items: flex-start;
  padding-top: 2rem;
  padding-left: 2rem;
  display: flex;
}

.document-link {
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: block;
}

.document-divider {
  width: 100%;
  height: .5px;
  background-color: var(--border--strokes-2);
  margin-top: 2rem;
}

.document-collection-item {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 1rem;
  display: flex;
}

.document-content-layout {
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;
  background-color: var(--background-colors--secondary);
  grid-template-rows: auto;
  grid-template-columns: 1fr 2fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.document-category-list {
  border-radius: var(--border--radius-small);
  background-color: var(--background-colors--secondary);
  padding: 2rem;
  position: -webkit-sticky;
  position: sticky;
  top: 6rem;
}

.feature-card-item {
  width: 100%;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  border-radius: var(--border--radius-small);
  flex-direction: row;
  grid-template-rows: auto;
  grid-template-columns: max-content 1fr;
  grid-auto-columns: 1fr;
  align-items: flex-start;
  padding: 2rem;
  transition: background-color .2s;
  display: grid;
  position: relative;
  overflow: hidden;
}

.feature-icon {
  grid-column-gap: .5rem;
  grid-row-gap: .5rem;
  align-items: center;
  display: flex;
}

.feature-content {
  flex-direction: column;
  align-items: flex-start;
  padding-top: .5rem;
  display: flex;
}

._4-column-list {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.use-case-component {
  grid-column-gap: 5rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  display: grid;
}

.text-size-medium-2 {
  color: #50636c;
  font-size: 1.125rem;
}

.feature-circle-2 {
  width: 10rem;
  height: 10rem;
  filter: blur(20px);
  background-color: #5c258d;
  border-radius: 50px;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: auto;
}

.text-color-primary-2 {
  color: #082130;
}

.team-member-component {
  grid-column-gap: 5rem;
  grid-row-gap: 5rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  justify-items: center;
  display: grid;
}

.team-member-image-wrapper {
  width: 100%;
  height: 100%;
  max-width: 30rem;
  flex-direction: column;
  flex: 1;
  align-items: center;
  display: flex;
  position: relative;
}

.heading,
.heading-2 {
  font-size: 2rem;
}

.heading-3 {
  font-style: normal;
  text-decoration: underline;
}

.flex-block {
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flex-block-2 {
  flex-flow: wrap;
  flex: 0 auto;
  align-content: center;
  align-items: center;
}

.heading-4 {
  margin-right: 0;
}

.paragraph {
  align-self: auto;
  margin-top: 13px;
  display: block;
}

@media screen and (min-width: 1280px) {
  .utility-form-block {
    position: relative;
  }

  .padding-section-large {
    padding-top: 4rem;
  }

  .event-content-metatag-list {
    grid-template-columns: 2fr 1.5fr 1fr 1fr;
  }
}

@media screen and (min-width: 1440px) {
  .header-image {
    border-color: var(--border--strokes-2);
  }

  .job-content-metatag-item {
    background-color: var(--background-colors--secondary);
  }
}

@media screen and (max-width: 991px) {
  h1 {
    font-size: 3.25rem;
  }

  h2 {
    font-size: 2.75rem;
  }

  h3 {
    font-size: 2.25rem;
  }

  h4 {
    font-size: 1.75rem;
  }

  .margin-large {
    margin: 2.5rem;
  }

  .margin-xlarge {
    margin: 3.5rem;
  }

  .margin-xxlarge {
    margin: 4.5rem;
  }

  .margin-huge {
    margin: 5rem;
  }

  .padding-xlarge {
    padding: 3.5rem;
  }

  .padding-xxlarge {
    padding: 4.5rem;
  }

  .margin-top {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .margin-bottom {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .margin-bottom.margin-xxlarge.is-tablet-medium {
    margin-bottom: 2rem;
  }

  .margin-vertical {
    margin-left: 0;
    margin-right: 0;
  }

  .padding-top {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .padding-bottom {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .padding-vertical {
    padding-left: 0;
    padding-right: 0;
  }

  .heading-style-h2 {
    font-size: 2.75rem;
  }

  .heading-style-h1 {
    font-size: 3.25rem;
  }

  .button.max-width-full.is-pricing21-button {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .heading-style-h4 {
    font-size: 1.75rem;
  }

  .heading-style-h3 {
    font-size: 2.25rem;
  }

  .padding-section-medium {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .padding-section-large {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .navbar-container {
    justify-content: space-between;
  }

  .navbar-logo-link,
  .navbar-logo-link.w--current {
    margin-right: 0;
  }

  .navbar-menu {
    height: 100dvh;
    background-color: var(--background-colors--primary);
    -webkit-text-fill-color: inherit;
    background-clip: border-box;
    border-bottom: 1px solid #000;
    align-items: flex-start;
    margin-left: 0;
    padding: 1rem 5% 6.5rem;
    transition: height .5s;
    position: absolute;
    overflow: auto;
  }

  .navbar-link {
    width: auto;
    padding: .75rem 0;
    font-size: 1.125rem;
  }

  .navbar-menu-right {
    flex-direction: column;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    align-items: stretch;
    margin-top: 1.5rem;
    display: block;
  }

  .navbar-menu-desktop {
    flex-direction: column;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    align-items: stretch;
    margin-top: 1.5rem;
    display: none;
  }

  .service-list {
    grid-column-gap: 2rem;
  }

  .feature-list {
    grid-template-columns: 1fr 1fr;
  }

  .layout-component {
    min-height: auto;
    grid-column-gap: 3rem;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
  }

  .video-header-lightbox-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .features-component {
    min-height: auto;
    grid-column-gap: 3rem;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
  }

  .integration-icon-wrapper {
    grid-column-gap: 1rem;
  }

  .testimonial-component {
    column-count: 2;
  }

  .blog-collection-list {
    grid-template-columns: 1fr;
  }

  .footer-newsletter-wrapper {
    justify-content: flex-start;
    margin-bottom: 4.5rem;
    display: block;
  }

  .footer-newsletter-text-wrapper {
    margin-bottom: 1.5rem;
  }

  .footer-form-block {
    min-width: auto;
  }

  .footer-top-wrapper {
    grid-row-gap: 3rem;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .footer-bottom-wrapper {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .footer-social-icons {
    margin-bottom: 2rem;
  }

  .heading-medium {
    font-size: 1.75rem;
  }

  .about-story-component {
    grid-column-gap: 3rem;
    grid-row-gap: 2rem;
  }

  .about-story-progress-bar-wrapper {
    top: 8%;
  }

  .about-story-timeline-step {
    grid-column-gap: 24px;
  }

  .beliefs-list {
    grid-column-gap: 2rem;
  }

  .team-list {
    grid-template-columns: 1fr 1fr;
  }

  .pricing-plans {
    grid-template-columns: 1fr;
  }

  .pricing-compare-top-row-price {
    font-size: 3.25rem;
  }

  .testimonial-single-component {
    min-height: auto;
    grid-column-gap: 2.5rem;
    grid-row-gap: 2.5rem;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
  }

  .values-content-bottom {
    grid-column-gap: 2rem;
    grid-template-columns: 1fr 1fr;
  }

  .change-log-component {
    grid-row-gap: 3rem;
    grid-template-columns: 1fr;
  }

  .change-log-date {
    position: static;
  }

  .content {
    margin-bottom: 3rem;
  }

  .contact-header-component {
    grid-column-gap: 2rem;
  }

  .heading-medium-text {
    font-size: 1.75rem;
  }

  .contact-locations-component {
    grid-column-gap: 3rem;
  }

  ._2-column-list,
  ._3-column-list {
    grid-template-columns: 1fr 1fr;
  }

  .login-component,
  .signup-component {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  ._1-column-list {
    grid-template-columns: 1fr 1fr;
  }

  .integration-content {
    grid-row-gap: 3rem;
    grid-template-columns: 1fr;
  }

  .application-details {
    position: static;
  }

  .content-component {
    grid-row-gap: 3rem;
    grid-template-columns: 1fr;
  }

  .user-content-component {
    grid-column-gap: 3rem;
    grid-row-gap: 3rem;
  }

  .team-social-icons {
    margin-bottom: 2rem;
  }

  .blog-post-header-title-wrapper {
    margin-bottom: 4rem;
  }

  .blog-post-header-image-wrapper {
    padding-top: 56.25%;
  }

  .blog-content {
    margin-bottom: 3rem;
  }

  .benefit-list {
    grid-column-gap: 2rem;
  }

  .media-content {
    grid-row-gap: 3rem;
    grid-template-columns: 1fr;
  }

  .menu-icon {
    width: 48px;
    height: 48px;
    color: var(--swatch--silver-steel\<deleted\|variable-a214e77f-c16f-d580-fd2e-9b27c061e17d\>);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    margin-right: -.5rem;
    padding-bottom: 0;
    padding-right: 0;
    display: flex;
  }

  .menu-icon-line-top {
    width: 24px;
    height: 2px;
    background-color: var(--button-primary--border);
    padding-bottom: 0;
    padding-right: 0;
  }

  .menu-icon-line-middle {
    width: 24px;
    height: 2px;
    background-color: var(--button-primary--border);
    color: #91969a;
    justify-content: center;
    align-items: center;
    margin-top: 6px;
    margin-bottom: 6px;
    padding-bottom: 0;
    padding-right: 0;
    display: flex;
  }

  .menu-icon-line-middle-inner {
    width: 4px;
    height: 0;
    padding-bottom: 0;
    padding-right: 0;
  }

  .menu-icon-line-bottom {
    width: 24px;
    height: 2px;
    background-color: var(--button-primary--border);
    color: #91969a;
    padding-bottom: 0;
    padding-right: 0;
  }

  .navbar-menu-button.w--open {
    background-color: rgba(0, 0, 0, 0);
  }

  .event-register,
  .integration-details,
  .customer-details,
  .job-application {
    position: static;
  }

  .header-2-component {
    min-height: auto;
    grid-row-gap: 4rem;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }

  .feature-1-list {
    grid-column-gap: 2rem;
  }

  .features-tab-wrapper {
    grid-column-gap: 3rem;
    grid-row-gap: 3rem;
    grid-template-columns: 1fr;
  }

  .results-component {
    min-height: auto;
    grid-column-gap: 3rem;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }

  .layout-2-component {
    grid-column-gap: 3rem;
    grid-template-columns: 1fr;
    justify-items: center;
  }

  .features-lightbox-image,
  .header-3-lightbox-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .features-7-list {
    grid-template-columns: 1fr 1fr;
  }

  .layout-3-component {
    min-height: auto;
    grid-column-gap: 3rem;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }

  .layout-video-component {
    min-height: auto;
    grid-column-gap: 3rem;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
  }

  .layout-video-lightbox-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .layout-4-row {
    grid-template-columns: 1fr 1fr;
  }

  .layout-4-card-large-content,
  .layout-4-card-small-content {
    padding: 2rem;
  }

  .testimonial-3-mask {
    width: 50%;
  }

  .testimonial-3-slide {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .testimonial-3-arrow {
    width: 3rem;
    height: 3rem;
  }

  .faq-2-component {
    min-height: auto;
    grid-column-gap: 3rem;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
  }

  .story-1-component {
    grid-column-gap: 3rem;
    grid-row-gap: 2rem;
  }

  .growth-1-component {
    min-height: auto;
    grid-column-gap: 3rem;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
  }

  .values-1-list {
    grid-column-gap: 2rem;
  }

  .team-1-list {
    grid-template-columns: 1fr 1fr;
  }

  .growth-3-component {
    grid-column-gap: 3rem;
    grid-row-gap: 2rem;
  }

  .values-2-list {
    grid-column-gap: 2rem;
  }

  .partners-component {
    min-height: auto;
    grid-column-gap: 3rem;
    grid-row-gap: 4rem;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
  }

  .team-2-list {
    grid-column-gap: 2rem;
    grid-template-columns: 1fr;
  }

  .pricing-1-component {
    min-height: auto;
    grid-column-gap: 3rem;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }

  .faq-3-component {
    grid-column-gap: 2rem;
    grid-row-gap: 4rem;
  }

  .testimonial-4-component {
    grid-column-gap: 2rem;
  }

  .pricing-3-comparison-top-row-content {
    padding: 1.5rem 1rem;
  }

  .pricing1-comparison-top-row-price-copy {
    font-size: 3.25rem;
  }

  .pricing-2-comparison-row-content.is-first {
    border-left-style: none;
  }

  .layout-1-card-content {
    padding: 2rem;
  }

  ._4-column-list {
    grid-template-columns: 1fr 1fr;
  }

  .use-case-component {
    grid-column-gap: 3rem;
    grid-row-gap: 2rem;
  }

  .team-member-component {
    grid-column-gap: 3rem;
    grid-row-gap: 3rem;
  }
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2.25rem;
  }

  h3 {
    font-size: 2rem;
  }

  h4 {
    font-size: 1.5rem;
    line-height: 1.4;
  }

  h5 {
    font-size: 1.25rem;
  }

  h6 {
    font-size: 1.125rem;
  }

  .hide-mobile-landscape {
    display: none;
  }

  .margin-xsmall {
    margin: .75rem;
  }

  .margin-small {
    margin: 1.25rem;
  }

  .margin-medium {
    margin: 1.5rem;
  }

  .margin-large {
    margin: 2rem;
  }

  .margin-xlarge {
    margin: 2.5rem;
  }

  .margin-xxlarge {
    margin: 3rem;
  }

  .margin-huge {
    margin: 3.5rem;
  }

  .padding-xsmall {
    padding: .75rem;
  }

  .padding-medium {
    padding: 1.5rem;
  }

  .padding-xlarge {
    padding: 2.5rem;
  }

  .padding-xxlarge {
    padding: 3rem;
  }

  .margin-top {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .margin-bottom {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .margin-vertical {
    margin-left: 0;
    margin-right: 0;
  }

  .padding-top {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .padding-bottom {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .padding-vertical {
    padding-left: 0;
    padding-right: 0;
  }

  .text-size-medium {
    font-size: 1rem;
  }

  .heading-style-h2 {
    font-size: 2.25rem;
  }

  .heading-style-h6 {
    font-size: 1.125rem;
  }

  .heading-style-h5 {
    font-size: 1.25rem;
  }

  .heading-style-h1 {
    font-size: 2.5rem;
  }

  .heading-style-h4 {
    font-size: 1.5rem;
    line-height: 1.4;
  }

  .heading-style-h3 {
    font-size: 2rem;
  }

  .text-size-large {
    font-size: 1.125rem;
  }

  .padding-section-medium {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .padding-section-large {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .navbar-component {
    min-height: 4rem;
  }

  .navbar-menu {
    padding-bottom: 6rem;
  }

  .header-blur {
    width: 30%;
  }

  .logo-list {
    grid-column-gap: 1rem;
    grid-template-columns: 10rem 10rem 10rem 10rem 10rem 10rem 10rem 10rem;
    padding-top: 1rem;
  }

  .logo-wrapper {
    padding: .75rem 1rem;
  }

  .logo {
    max-height: 3rem;
  }

  .service-list {
    grid-row-gap: 3rem;
    grid-template-columns: 1fr;
  }

  .feature-list {
    grid-template-columns: 1fr;
  }

  .layout-component,
  .features-component {
    grid-row-gap: 3rem;
    grid-template-columns: 1fr;
  }

  .features-content-left {
    position: static;
  }

  .layout-1-component {
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
  }

  .layout-1-row {
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
    grid-template-columns: 1fr;
  }

  .layout-1-card {
    grid-template-columns: 1fr;
  }

  .testimonial-component {
    column-count: 1;
  }

  .testimonial-content {
    padding: 1.5rem;
  }

  .testimonial-client {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }

  .testimonial-client-image-wrapper {
    margin-bottom: 1rem;
    margin-right: 0;
  }

  .blog-collection-list {
    grid-template-columns: 1fr;
  }

  .faq-question {
    padding: 1rem 1.25rem;
  }

  .faq-icon {
    width: 1.75rem;
    min-width: 1.75rem;
  }

  .faq-answer {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .footer-newsletter-wrapper {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 3rem;
  }

  .footer-top-wrapper {
    grid-row-gap: 2.5rem;
  }

  .footer-bottom-wrapper {
    flex-direction: column-reverse;
    align-items: flex-start;
    padding-bottom: 1rem;
  }

  .footer-legal-list {
    grid-column-gap: 0rem;
    grid-row-gap: 1rem;
    flex-direction: column;
    grid-auto-flow: row;
  }

  .footer-credit-text {
    margin-top: 1rem;
  }

  .style-guide-content-layout {
    grid-template-columns: 1fr;
    grid-template-areas: ".";
  }

  .style-guide-menu {
    display: none;
  }

  .heading-medium {
    font-size: 1.5rem;
    line-height: 1.4;
  }

  .about-story-component {
    grid-template-columns: 1fr;
  }

  .about-story-progress-bar-wrapper {
    left: 1.7rem;
  }

  .about-story-timeline-icon-wrapper {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .beliefs-list,
  .team-list {
    grid-row-gap: 3rem;
    grid-template-columns: 1fr;
  }

  .pricing-plans {
    grid-template-columns: 1fr;
  }

  .pricing-plan {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .pricing-compare-tabs-menu {
    margin-bottom: 3rem;
  }

  .pricing-compare-top-row {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .pricing-compare-empty-space {
    display: none;
  }

  .pricing-compare-top-row-price {
    font-size: 2.5rem;
  }

  .pricing-compare-row {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .pricing-compare-feature,
  .pricing-compare-row-content {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .pricing-compare-bottom-row {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .logo1-component {
    padding-top: 1rem;
  }

  .logo1-logo {
    max-height: 3rem;
  }

  .testimonial-single-component {
    grid-row-gap: 3rem;
    grid-template-columns: 1fr;
  }

  .testimonial-single-client {
    width: 100%;
    align-items: center;
  }

  .values-content-bottom {
    grid-row-gap: 3rem;
    grid-template-columns: 1fr;
  }

  .career-job-department {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .arrow-icon {
    width: 28px;
  }

  .career-list {
    margin-bottom: 1.5rem;
  }

  .career-item {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .content {
    margin-bottom: 2rem;
  }

  .contact-header-component {
    grid-row-gap: 3rem;
    grid-template-columns: 1fr;
  }

  .heading-medium-text {
    font-size: 1.5rem;
    line-height: 1.4;
  }

  .form-field-2col.is-mobile-1col {
    grid-template-columns: 1fr;
  }

  .contact-locations-component {
    grid-row-gap: 3rem;
    grid-template-columns: 1fr;
  }

  ._2-column-list,
  ._3-column-list {
    grid-template-columns: 1fr;
  }

  .navbar-2,
  .login-footer,
  .signup-footer {
    height: 4rem;
  }

  ._1-column-list {
    grid-template-columns: 1fr;
  }

  .job-content-metatag-list {
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
  }

  .user-content-component {
    grid-template-columns: 1fr;
  }

  .blog-post-header-title-wrapper {
    margin-bottom: 3rem;
  }

  .blog-content {
    margin-bottom: 2rem;
  }

  .blog-divider {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .blog-1-header-category-menu {
    width: 100vw;
    margin-left: -5vw;
    padding-left: 5vw;
    overflow: scroll;
  }

  .blog-1-header-category-menu.no-scrollbar {
    grid-row-gap: 1rem;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 3rem;
  }

  .benefit-list {
    grid-row-gap: 3rem;
    grid-template-columns: 1fr;
  }

  .event-content-metatag-list {
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
  }

  .event-collection-list {
    grid-template-columns: 1fr;
  }

  .forgot-password-footer {
    height: 4rem;
  }

  .header-2-component {
    grid-row-gap: 3rem;
  }

  .feature-1-list {
    grid-row-gap: 3rem;
    grid-template-columns: 1fr;
  }

  .tabs-menu {
    flex-wrap: wrap;
    margin-bottom: 3rem;
  }

  .results-component,
  .layout-2-component {
    grid-row-gap: 3rem;
    grid-template-columns: 1fr;
  }

  .faq-1-question {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .faq-1-icon {
    width: 1.75rem;
    min-width: 1.75rem;
  }

  .features-7-list,
  .layout-3-component,
  .layout-video-component {
    grid-row-gap: 3rem;
    grid-template-columns: 1fr;
  }

  .layout-4-component {
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
  }

  .layout-4-row {
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
    grid-template-columns: 1fr;
  }

  .layout-4-card-large {
    grid-template-columns: 1fr;
  }

  .layout-4-card-large-content {
    padding: 1.5rem;
  }

  .layout-4-card-small {
    grid-template-columns: 1fr;
  }

  .layout-4-card-small-content {
    padding: 1.5rem;
  }

  .testimonial-3-component {
    padding-bottom: 3rem;
    padding-left: 0;
    padding-right: 0;
  }

  .testimonial-3-mask {
    width: 100%;
  }

  .testimonial-3-slide {
    margin-right: 2rem;
    padding-left: 0;
    padding-right: 0;
  }

  .testimonial-3-content {
    padding: 1.5rem;
  }

  .testimonial-3-client {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }

  .testimonial-3-client-image-wrapper {
    margin-bottom: 1rem;
    margin-right: 0;
  }

  .testimonial-3-arrow {
    bottom: 3rem;
  }

  .faq-2-component {
    grid-row-gap: 3rem;
    grid-template-columns: 1fr;
  }

  .faq-2-question {
    padding: 1rem 1.25rem;
  }

  .faq-2-icon {
    width: 1.75rem;
    min-width: 1.75rem;
  }

  .faq-2-answer {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .story-1-component {
    grid-template-columns: 1fr;
  }

  .growth-1-component,
  .values-1-list,
  .team-1-list {
    grid-row-gap: 3rem;
    grid-template-columns: 1fr;
  }

  .growth-3-component {
    grid-template-columns: 1fr;
  }

  .values-2-list,
  .partners-component {
    grid-row-gap: 3rem;
    grid-template-columns: 1fr;
  }

  .partners-wrapper {
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .partners-logo {
    max-height: 3rem;
  }

  .team-2-list {
    grid-row-gap: 3rem;
    grid-template-columns: 1fr;
  }

  .team-2-item {
    grid-row-gap: 1.5rem;
    grid-template-columns: 1fr;
  }

  .team-2-image-wrapper {
    height: 100%;
  }

  .pricing-1-component {
    grid-row-gap: 3rem;
    grid-template-columns: 1fr;
  }

  .pricing-1-plan {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .pricing-1-feature-list {
    grid-template-columns: 1fr;
  }

  .faq-3-component {
    grid-row-gap: 3rem;
    grid-template-columns: 1fr;
  }

  .faq-3-list {
    grid-row-gap: 2.5rem;
  }

  .pricing-header-2-plans {
    grid-template-columns: 1fr;
  }

  .pricing-header-2-plan {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .pricing-header-2-feature-list {
    grid-template-columns: 1fr;
  }

  .testimonial-4-component {
    grid-row-gap: 3rem;
    grid-template-columns: 1fr;
  }

  .pricing-2-tabs-menu {
    margin-bottom: 3rem;
  }

  .pricing-2-comparison-top-row {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .pricing-3-comparison-empty-space {
    display: none;
  }

  .pricing-3-comparison-top-row-content.is-first {
    border-left-style: none;
  }

  .pricing1-comparison-top-row-price-copy {
    font-size: 2.5rem;
  }

  .pricing-2-comparison-row {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .pricing-2-comparison-feature {
    border-bottom: 1px solid #000;
    padding-right: 1rem;
  }

  .pricing-2-comparison-row-content {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .layout-1-card-content {
    padding: 1.5rem;
  }

  .docs-component {
    padding-top: 0;
    padding-left: 0;
  }

  .document-content-layout {
    grid-template-columns: 1fr;
    grid-template-areas: ".";
  }

  .document-category-list {
    display: none;
  }

  ._4-column-list,
  .use-case-component {
    grid-template-columns: 1fr;
  }

  .text-size-medium-2 {
    font-size: 1rem;
  }

  .team-member-component {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 479px) {
  h1 {
    font-size: 2.5rem;
  }

  .margin-top {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .margin-bottom {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .margin-vertical {
    margin-left: 0;
    margin-right: 0;
  }

  .padding-top {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .padding-bottom {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .padding-vertical {
    padding-left: 0;
    padding-right: 0;
  }

  .button.max-width-full.is-pricing-button {
    padding: .25rem 1rem;
  }

  .button.max-width-full.is-pricing21-button {
    padding: .25rem .75rem;
  }

  .logo-image {
    margin-top: 0;
  }

  .header-blur {
    width: 10%;
  }

  .logo-list {
    justify-content: center;
  }

  .logo-wrapper {
    justify-content: center;
    align-items: flex-start;
  }

  .service-list,
  .layout-component,
  .features-component,
  .layout-1-row {
    grid-template-columns: 1fr;
  }

  .left-padding {
    padding-left: 1rem;
  }

  .integration-icon {
    width: 4rem;
    height: 4rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .right-padding {
    padding-right: 1rem;
  }

  .footer-form {
    grid-row-gap: 12px;
    flex-direction: column;
    grid-template-columns: 1fr;
  }

  .footer-top-wrapper {
    grid-row-gap: 2.5rem;
    grid-template-columns: 1fr;
  }

  .style-guide-content-layout {
    grid-template-areas: ".";
    grid-auto-columns: .25fr;
  }

  .style-guide-content-list,
  .about-story-component,
  .beliefs-list,
  .team-list {
    grid-template-columns: 1fr;
  }

  .pricing-compare-top-row-price {
    font-size: 1.5rem;
  }

  .logo1-component {
    justify-content: center;
  }

  .testimonial-single-component {
    grid-template-columns: 1fr;
  }

  .testimonial-single-divider {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .values-content-bottom {
    grid-template-columns: 1fr;
  }

  .career-title-wrapper {
    display: block;
  }

  .career-title-heading {
    margin-bottom: 1.25rem;
  }

  .contact-header-component,
  .contact-locations-component {
    grid-template-columns: 1fr;
  }

  .block-wrapper {
    padding: 1rem;
  }

  .content-form-wrapper {
    grid-row-gap: 12px;
    flex-direction: column;
    grid-template-columns: 1fr;
  }

  .blog-post-header-content-top {
    flex-direction: column;
    align-items: flex-start;
  }

  .blog-post-header-author-wrapper {
    margin-bottom: 1rem;
  }

  .blog-content-bottom {
    flex-direction: column;
    align-items: flex-start;
  }

  .blog-share-wrapper {
    width: 100%;
    margin-bottom: 2rem;
  }

  .blog-1-header-category-menu.no-scrollbar {
    flex-direction: column;
  }

  .benefit-list,
  .header-2-component,
  .feature-1-list,
  .results-component,
  .results-item-list {
    grid-template-columns: 1fr;
  }

  .layout-2-component {
    grid-row-gap: 48px;
    grid-template-columns: 1fr;
  }

  .features-7-list,
  .layout-3-component,
  .layout-video-component,
  .layout-4-row {
    grid-template-columns: 1fr;
  }

  .layout-4-card-large-content {
    display: block;
  }

  .faq-2-component,
  .story-1-component,
  .growth-1-component,
  .growth-1-item-list,
  .values-1-list,
  .team-1-list,
  .growth-3-component,
  .growth-3-item-list,
  .values-2-list,
  .partners-component,
  .partners-list {
    grid-template-columns: 1fr;
  }

  .partners-wrapper {
    justify-content: center;
    align-items: flex-start;
  }

  .team-2-item {
    grid-column-gap: 1.25rem;
    grid-row-gap: 1.25rem;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
  }

  .team-2-image-wrapper {
    padding-top: 66%;
  }

  .pricing-1-component,
  .pricing-2-content-list,
  .faq-3-component {
    grid-template-columns: 1fr;
  }

  .pricing-3-comparison-top-row-content {
    padding: 1rem .5rem;
  }

  .pricing1-comparison-top-row-price-copy {
    font-size: 1.5rem;
  }

  .background-gradient {
    background-image: linear-gradient(to bottom, var(--swatch--transparent), var(--swatch--transparent) 50%, var(--background-colors--primary)), url('../images/Dot_1Dot.png'), linear-gradient(to bottom, var(--background-colors--gradient-1), var(--background-colors--gradient-2) 50%, var(--background-colors--primary));
    background-position: 0 0, 0 0, 0 0;
    background-size: auto, 20px, auto;
  }

  .document-content-layout {
    grid-template-areas: ".";
    grid-auto-columns: .25fr;
  }

  .use-case-component {
    grid-template-columns: 1fr;
  }
}

#w-node-_1b14577b-1830-3b34-d267-013e1dd74ba1-7ee495b7 {
  justify-self: center;
}

#w-node-_9a140f91-e246-04bc-d52b-5c3e2515b4ab-7ee495c4 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_5f896e56-9c9d-14ee-dc4e-58eb5686b07e-7ee495c4,
#w-node-b6da4d12-5850-9688-eaa7-4e04483bad26-7ee495c5,
#w-node-b6da4d12-5850-9688-eaa7-4e04483bad28-7ee495c5,
#w-node-b6da4d12-5850-9688-eaa7-4e04483bad2a-7ee495c5,
#w-node-b6da4d12-5850-9688-eaa7-4e04483bad2c-7ee495c5,
#w-node-b6da4d12-5850-9688-eaa7-4e04483bad2e-7ee495c5,
#w-node-b6da4d12-5850-9688-eaa7-4e04483bad30-7ee495c5,
#w-node-b6da4d12-5850-9688-eaa7-4e04483bad32-7ee495c5,
#w-node-b6da4d12-5850-9688-eaa7-4e04483bad34-7ee495c5 {
  justify-self: center;
}

#w-node-b6da4d12-5850-9688-eaa7-4e04483bad43-7ee495c5,
#w-node-_35af438e-40aa-1734-0fcd-d6cabfb32af9-7ee495d1 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_35af438e-40aa-1734-0fcd-d6cabfb32b1c-7ee495d1 {
  justify-self: center;
}

#w-node-_48e0a418-500f-5195-d0c1-af0f5f6d5a6f-7ee495d1 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_48e0a418-500f-5195-d0c1-af0f5f6d5a92-7ee495d1 {
  justify-self: center;
}

#w-node-_1a98e105-e6de-3056-0ab1-389a75e2ce66-7ee495d1 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_1a98e105-e6de-3056-0ab1-389a75e2ce89-7ee495d1 {
  justify-self: center;
}

#w-node-_955dfd2e-89b8-04a1-930d-d91949ce2c33-7ee495d5 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_955dfd2e-89b8-04a1-930d-d91949ce2c56-7ee495d5 {
  justify-self: center;
}

#w-node-_785a6052-9015-c388-31dd-d4e5502911b7-7ee495d6,
#w-node-_785a6052-9015-c388-31dd-d4e5502911b9-7ee495d6 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_27fc91fc-3cf2-b067-4bc5-cbb01acecb45-7ee495da {
  align-self: start;
}

#w-node-_8f2da059-19ea-14a1-8cbd-93cd82a75626-7ee495db,
#w-node-_8f2da059-19ea-14a1-8cbd-93cd82a7562c-7ee495db,
#w-node-_8f2da059-19ea-14a1-8cbd-93cd82a75632-7ee495db,
#w-node-_8f2da059-19ea-14a1-8cbd-93cd82a75638-7ee495db,
#w-node-cadf5426-9df8-9226-595c-7e3b77ab9a73-7ee495db,
#w-node-_1e997195-bb94-e695-c75b-73efa13d298c-7ee49623,
#w-node-_1e997195-bb94-e695-c75b-73efa13d2992-7ee49623,
#w-node-_1e997195-bb94-e695-c75b-73efa13d2998-7ee49623,
#w-node-_1e997195-bb94-e695-c75b-73efa13d299e-7ee49623,
#field-2.w-node-_78652837-71dc-2241-3915-6c27332e0723-7ee49623,
#w-node-e8f5869b-c38c-4a68-d909-67b51d81827c-7ee4963b,
#w-node-e8f5869b-c38c-4a68-d909-67b51d81827a-7ee4963b {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-e4198be0-edef-9578-e69d-8e58cd1c2a47-7ee49651 {
  align-self: start;
}

#w-node-_97ee77c4-fec5-9a98-24ca-a8c2cab9e82c-7ee49655 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_6cdea805-f69f-6ccf-1355-4cff2ccb58ca-7ee49671 {
  justify-self: center;
}

#w-node-_6cdea805-f69f-6ccf-1355-4cff2ccb58cc-7ee49671 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_6f36c9b0-b264-5beb-0d49-4858d210df48-7ee49688 {
  grid-area: 1 / 1 / 2 / 2;
}

#w-node-_4eaf6d6b-5ac2-0d33-d95b-eadf3a775971-7ee49689 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_4eaf6d6b-5ac2-0d33-d95b-eadf3a77598b-7ee49689,
#w-node-_4eaf6d6b-5ac2-0d33-d95b-eadf3a775990-7ee49689,
#w-node-_4eaf6d6b-5ac2-0d33-d95b-eadf3a775995-7ee49689,
#w-node-_4eaf6d6b-5ac2-0d33-d95b-eadf3a7759ab-7ee49689,
#w-node-_4eaf6d6b-5ac2-0d33-d95b-eadf3a7759b0-7ee49689,
#w-node-_4eaf6d6b-5ac2-0d33-d95b-eadf3a7759b5-7ee49689 {
  align-self: start;
}

#w-node-_4eaf6d6b-5ac2-0d33-d95b-eadf3a775a08-7ee49689 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_4eaf6d6b-5ac2-0d33-d95b-eadf3a775a09-7ee49689 {
  grid-area: span 2 / span 1 / span 2 / span 1;
}

#w-node-_4eaf6d6b-5ac2-0d33-d95b-eadf3a775a0a-7ee49689 {
  align-self: start;
}

#w-node-_49d8d92e-4b91-7f07-7c84-2cafa9be1f47-7ee4968a,
#w-node-_49d8d92e-4b91-7f07-7c84-2cafa9be1f51-7ee4968a,
#w-node-_80a9bde7-a1b2-e7f5-b78f-9d227ac98273-7ee4968a,
#w-node-_3588eff5-6a37-2cd0-a1ac-5b597ce94641-7ee4968a,
#w-node-_6b4dfaaf-6769-e636-0bfd-862b5a99e9aa-7ee4968c,
#w-node-_6b4dfaaf-6769-e636-0bfd-862b5a99e9b3-7ee4968c,
#w-node-_6b4dfaaf-6769-e636-0bfd-862b5a99e9bd-7ee4968c {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-e52775ba-4967-4575-571c-5d22f6863b2e-7ee4968d {
  justify-self: center;
}

#w-node-e52775ba-4967-4575-571c-5d22f6863b2f-7ee4968d,
#w-node-e52775ba-4967-4575-571c-5d22f6863b31-7ee4968d,
#w-node-e52775ba-4967-4575-571c-5d22f6863b32-7ee4968d,
#w-node-e52775ba-4967-4575-571c-5d22f6863b36-7ee4968d,
#w-node-e52775ba-4967-4575-571c-5d22f6863b3a-7ee4968d {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_329f780e-4533-e13a-dd7a-98d3691b5151-7ee496a6,
#w-node-_329f780e-4533-e13a-dd7a-98d3691b5156-7ee496a6,
#w-node-_329f780e-4533-e13a-dd7a-98d3691b515b-7ee496a6,
#w-node-_7483c404-17d7-468c-f214-0dd0fd4395ab-7ee496a8,
#w-node-_7483c404-17d7-468c-f214-0dd0fd4395b1-7ee496a8,
#w-node-_7483c404-17d7-468c-f214-0dd0fd4395b6-7ee496a8,
#w-node-_7483c404-17d7-468c-f214-0dd0fd4395cf-7ee496a8,
#w-node-_7483c404-17d7-468c-f214-0dd0fd4395d4-7ee496a8,
#w-node-_7483c404-17d7-468c-f214-0dd0fd4395d9-7ee496a8,
#w-node-_7483c404-17d7-468c-f214-0dd0fd4395de-7ee496a8,
#w-node-_7483c404-17d7-468c-f214-0dd0fd4395e3-7ee496a8,
#w-node-_7483c404-17d7-468c-f214-0dd0fd4395fb-7ee496a8,
#w-node-_7483c404-17d7-468c-f214-0dd0fd439600-7ee496a8,
#w-node-_7483c404-17d7-468c-f214-0dd0fd439605-7ee496a8,
#w-node-_7483c404-17d7-468c-f214-0dd0fd43960a-7ee496a8,
#w-node-_7483c404-17d7-468c-f214-0dd0fd43960f-7ee496a8,
#w-node-d3f4ed8a-f695-212f-9460-ccc8b1c160ec-7ee496a8,
#w-node-d3f4ed8a-f695-212f-9460-ccc8b1c160f2-7ee496a8,
#w-node-d3f4ed8a-f695-212f-9460-ccc8b1c160f7-7ee496a8,
#w-node-d3f4ed8a-f695-212f-9460-ccc8b1c16110-7ee496a8,
#w-node-d3f4ed8a-f695-212f-9460-ccc8b1c16115-7ee496a8,
#w-node-d3f4ed8a-f695-212f-9460-ccc8b1c1611a-7ee496a8,
#w-node-d3f4ed8a-f695-212f-9460-ccc8b1c1611f-7ee496a8,
#w-node-d3f4ed8a-f695-212f-9460-ccc8b1c16124-7ee496a8,
#w-node-_24c15ff6-a9ec-d3d8-d516-a2c09c23f23f-7ee496a8,
#w-node-_24c15ff6-a9ec-d3d8-d516-a2c09c23f244-7ee496a8,
#w-node-_24c15ff6-a9ec-d3d8-d516-a2c09c23f249-7ee496a8,
#w-node-_24c15ff6-a9ec-d3d8-d516-a2c09c23f24e-7ee496a8,
#w-node-_24c15ff6-a9ec-d3d8-d516-a2c09c23f253-7ee496a8,
#w-node-_6e72f358-c84e-c155-b3cf-9724d396ddfb-7ee496bf,
#w-node-_6e72f358-c84e-c155-b3cf-9724d396de05-7ee496bf,
#w-node-_6e72f358-c84e-c155-b3cf-9724d396de0f-7ee496bf,
#w-node-_6e72f358-c84e-c155-b3cf-9724d396de2c-7ee496bf,
#w-node-_6e72f358-c84e-c155-b3cf-9724d396de32-7ee496bf,
#w-node-_6e72f358-c84e-c155-b3cf-9724d396de38-7ee496bf,
#w-node-_6e72f358-c84e-c155-b3cf-9724d396de3d-7ee496bf,
#w-node-_6e72f358-c84e-c155-b3cf-9724d396de43-7ee496bf,
#w-node-_6e72f358-c84e-c155-b3cf-9724d396de49-7ee496bf,
#w-node-_6e72f358-c84e-c155-b3cf-9724d396de4e-7ee496bf,
#w-node-_6e72f358-c84e-c155-b3cf-9724d396de53-7ee496bf,
#w-node-_6e72f358-c84e-c155-b3cf-9724d396de58-7ee496bf,
#w-node-_6e72f358-c84e-c155-b3cf-9724d396de5e-7ee496bf,
#w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2b62-7ee496c0,
#w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2b67-7ee496c0,
#w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2b6c-7ee496c0,
#w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2b71-7ee496c0,
#w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2b76-7ee496c0,
#w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2b7b-7ee496c0,
#w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2b98-7ee496c0,
#w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2b9d-7ee496c0,
#w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2ba2-7ee496c0,
#w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2ba7-7ee496c0,
#w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2bac-7ee496c0,
#w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2bb1-7ee496c0,
#w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2bb6-7ee496c0,
#w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2bbb-7ee496c0,
#w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2bdc-7ee496c0,
#w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2be1-7ee496c0,
#w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2be6-7ee496c0,
#w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2beb-7ee496c0,
#w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2bf0-7ee496c0,
#w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2bf5-7ee496c0,
#w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2c14-7ee496c0,
#w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2c19-7ee496c0,
#w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2c1e-7ee496c0,
#w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2c23-7ee496c0,
#w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2c28-7ee496c0,
#w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2c2d-7ee496c0,
#w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2c32-7ee496c0,
#w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2c37-7ee496c0 {
  align-self: start;
}

#w-node-_1eaae32b-2cdf-693c-ae15-15bcd1ef7b3b-7ee496c2 {
  justify-self: stretch;
}

#w-node-_4590ee03-4bf7-d1b0-c60b-aff2bfcda454-7ee496c4 {
  justify-self: center;
}

#w-node-_4590ee03-4bf7-d1b0-c60b-aff2bfcda456-7ee496c4,
#w-node-_4590ee03-4bf7-d1b0-c60b-aff2bfcda457-7ee496c4,
#w-node-_4590ee03-4bf7-d1b0-c60b-aff2bfcda458-7ee496c4,
#w-node-_4590ee03-4bf7-d1b0-c60b-aff2bfcda45c-7ee496c4,
#w-node-_4590ee03-4bf7-d1b0-c60b-aff2bfcda460-7ee496c4 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_23d411a2-8fc8-6b0e-3b97-6a648a83d664-7ee496c5 {
  align-self: start;
}

#w-node-_23d411a2-8fc8-6b0e-3b97-6a648a83d685-7ee496c5,
#w-node-_23d411a2-8fc8-6b0e-3b97-6a648a83d68a-7ee496c5,
#w-node-_23d411a2-8fc8-6b0e-3b97-6a648a83d68f-7ee496c5,
#w-node-_23d411a2-8fc8-6b0e-3b97-6a648a83d694-7ee496c5,
#w-node-_23d411a2-8fc8-6b0e-3b97-6a648a83d69e-7ee496c5,
#w-node-_23d411a2-8fc8-6b0e-3b97-6a648a83d6a3-7ee496c5,
#w-node-_23d411a2-8fc8-6b0e-3b97-6a648a83d6a8-7ee496c5,
#w-node-_23d411a2-8fc8-6b0e-3b97-6a648a83d6ad-7ee496c5,
#w-node-_23d411a2-8fc8-6b0e-3b97-6a648a83d6b2-7ee496c5,
#w-node-_23d411a2-8fc8-6b0e-3b97-6a648a83d6b7-7ee496c5,
#w-node-b753a3a2-2449-817f-fe55-973afd65bc20-7ee496c5,
#w-node-_23d411a2-8fc8-6b0e-3b97-6a648a83d6bc-7ee496c5,
#w-node-_23d411a2-8fc8-6b0e-3b97-6a648a83d730-7ee496c5,
#w-node-_23d411a2-8fc8-6b0e-3b97-6a648a83d736-7ee496c5,
#w-node-_23d411a2-8fc8-6b0e-3b97-6a648a83d73c-7ee496c5,
#w-node-_23d411a2-8fc8-6b0e-3b97-6a648a83d742-7ee496c5,
#w-node-_23d411a2-8fc8-6b0e-3b97-6a648a83d748-7ee496c5 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

@media screen and (max-width: 991px) {
  #w-node-_849b43d0-7c73-0723-8a90-d917a773dfab-ea008dd8 {
    grid-area: 1 / 1 / 2 / 4;
  }

  #w-node-_4eaf6d6b-5ac2-0d33-d95b-eadf3a775a09-7ee49689 {
    grid-column: span 2 / span 2;
  }
}

@media screen and (max-width: 767px) {
  #w-node-_849b43d0-7c73-0723-8a90-d917a773dfab-ea008dd8 {
    grid-area: 1 / 1 / 2 / 4;
  }

  #w-node-_7bddb5ee-f1e4-9379-42d0-3c31681a5ea3-7ee49655 {
    order: -9999;
  }

  #w-node-_6f36c9b0-b264-5beb-0d49-4858d210df32-7ee49688 {
    grid-area: 1 / 1 / 2 / 2;
  }

  #w-node-_6f36c9b0-b264-5beb-0d49-4858d210df48-7ee49688 {
    grid-row: 2 / 3;
  }

  #w-node-_4eaf6d6b-5ac2-0d33-d95b-eadf3a77599a-7ee49689,
  #w-node-_4eaf6d6b-5ac2-0d33-d95b-eadf3a7759a1-7ee49689 {
    order: 9999;
  }

  #w-node-_4eaf6d6b-5ac2-0d33-d95b-eadf3a775a09-7ee49689 {
    grid-column: span 1 / span 1;
  }

  #w-node-_329f780e-4533-e13a-dd7a-98d3691b5160-7ee496a6 {
    order: 9999;
  }

  #w-node-b64df882-1f68-dbaf-0343-e57c6b236c64-7ee496a8,
  #w-node-b64df882-1f68-dbaf-0343-e57c6b236c71-7ee496a8,
  #w-node-b64df882-1f68-dbaf-0343-e57c6b236c7b-7ee496a8,
  #w-node-b64df882-1f68-dbaf-0343-e57c6b236c84-7ee496a8,
  #w-node-b64df882-1f68-dbaf-0343-e57c6b236c8d-7ee496a8,
  #w-node-e842e154-2392-c9c9-623c-354a627ab6b0-7ee496a8,
  #w-node-e842e154-2392-c9c9-623c-354a627ab6bd-7ee496a8,
  #w-node-e842e154-2392-c9c9-623c-354a627ab6c7-7ee496a8,
  #w-node-e842e154-2392-c9c9-623c-354a627ab6d0-7ee496a8,
  #w-node-e842e154-2392-c9c9-623c-354a627ab6d9-7ee496a8,
  #w-node-_5b3ad159-674c-a623-6c2d-a9205a56f47e-7ee496a8,
  #w-node-_5b3ad159-674c-a623-6c2d-a9205a56f48b-7ee496a8,
  #w-node-_5b3ad159-674c-a623-6c2d-a9205a56f495-7ee496a8,
  #w-node-_5b3ad159-674c-a623-6c2d-a9205a56f49e-7ee496a8,
  #w-node-_5b3ad159-674c-a623-6c2d-a9205a56f4a7-7ee496a8,
  #w-node-_72259d55-2010-6d89-4374-c6f485c1595d-7ee496a8,
  #w-node-_72259d55-2010-6d89-4374-c6f485c1596a-7ee496a8,
  #w-node-_72259d55-2010-6d89-4374-c6f485c15974-7ee496a8,
  #w-node-_72259d55-2010-6d89-4374-c6f485c1597d-7ee496a8,
  #w-node-_72259d55-2010-6d89-4374-c6f485c15986-7ee496a8,
  #w-node-_9d9677ac-b9a2-9812-d215-0a856f1a9b04-7ee496a8,
  #w-node-_9d9677ac-b9a2-9812-d215-0a856f1a9b11-7ee496a8,
  #w-node-_9d9677ac-b9a2-9812-d215-0a856f1a9b1b-7ee496a8,
  #w-node-_9d9677ac-b9a2-9812-d215-0a856f1a9b24-7ee496a8,
  #w-node-_9d9677ac-b9a2-9812-d215-0a856f1a9b2d-7ee496a8,
  #w-node-f748956e-416d-18f6-5f25-d4a5ac029628-7ee496a8,
  #w-node-f748956e-416d-18f6-5f25-d4a5ac029635-7ee496a8,
  #w-node-f748956e-416d-18f6-5f25-d4a5ac02963f-7ee496a8,
  #w-node-f748956e-416d-18f6-5f25-d4a5ac029648-7ee496a8,
  #w-node-f748956e-416d-18f6-5f25-d4a5ac029651-7ee496a8 {
    grid-area: span 1 / span 3 / span 1 / span 3;
  }

  #w-node-d3f4ed8a-f695-212f-9460-ccc8b1c1632d-7ee496a8 {
    order: -9999;
  }

  #w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2caf-7ee496c0,
  #w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2cbc-7ee496c0,
  #w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2cc6-7ee496c0,
  #w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2cd0-7ee496c0,
  #w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2cd9-7ee496c0,
  #w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2ce5-7ee496c0,
  #w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2cf2-7ee496c0,
  #w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2cfc-7ee496c0,
  #w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2d06-7ee496c0,
  #w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2d0f-7ee496c0,
  #w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2d1b-7ee496c0,
  #w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2d28-7ee496c0,
  #w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2d32-7ee496c0,
  #w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2d3c-7ee496c0,
  #w-node-_80dbbebe-7d70-52e7-0d07-6f4f24da2d45-7ee496c0,
  #w-node-_37e4da8e-1f0e-db93-b25f-3e355b891ef7-7ee496c0,
  #w-node-_37e4da8e-1f0e-db93-b25f-3e355b891f04-7ee496c0,
  #w-node-_37e4da8e-1f0e-db93-b25f-3e355b891f0e-7ee496c0,
  #w-node-_37e4da8e-1f0e-db93-b25f-3e355b891f18-7ee496c0,
  #w-node-_37e4da8e-1f0e-db93-b25f-3e355b891f21-7ee496c0,
  #w-node-_7172c67a-97b4-74bb-a0d1-c999a04791bc-7ee496c0,
  #w-node-_7172c67a-97b4-74bb-a0d1-c999a04791c9-7ee496c0,
  #w-node-_7172c67a-97b4-74bb-a0d1-c999a04791d3-7ee496c0,
  #w-node-_7172c67a-97b4-74bb-a0d1-c999a04791dd-7ee496c0,
  #w-node-_7172c67a-97b4-74bb-a0d1-c999a04791e6-7ee496c0,
  #w-node-_6ed2c3fc-6f7b-ce9c-be2c-b0b60d84ecd1-7ee496c0,
  #w-node-_6ed2c3fc-6f7b-ce9c-be2c-b0b60d84ecde-7ee496c0,
  #w-node-_6ed2c3fc-6f7b-ce9c-be2c-b0b60d84ece8-7ee496c0,
  #w-node-_6ed2c3fc-6f7b-ce9c-be2c-b0b60d84ecf2-7ee496c0,
  #w-node-_6ed2c3fc-6f7b-ce9c-be2c-b0b60d84ecfb-7ee496c0 {
    grid-area: span 1 / span 3 / span 1 / span 3;
  }

  #w-node-_803fc772-9b79-74ec-534f-9c7ddd70c2c3-7ee496d4 {
    order: -9999;
  }
}

@media screen and (max-width: 479px) {

  #w-node-_849b43d0-7c73-0723-8a90-d917a773dfab-ea008dd8,
  #w-node-f160da43-e498-fd81-9e04-0c81ea008e39-ea008dd8 {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }

  #w-node-_4eaf6d6b-5ac2-0d33-d95b-eadf3a775a09-7ee49689 {
    grid-column: span 1 / span 1;
  }

  #w-node-_23d411a2-8fc8-6b0e-3b97-6a648a83d67c-7ee496c5 {
    grid-area: span 1 / span 2 / span 1 / span 2;
  }
}